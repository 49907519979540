<template>
	<div class="mes_container">
		<div
			class="sub21_con02_title"
			:data-aos="isMiniMobile ? '' : 'fade-down'"
			data-aos-offset="300"
			data-aos-duration="500"
		>
			<p class="sub21_text08">MES</p>
			<p class="sub21_text06">Manufacturing Execution System</p>
			<p class="sub21_text02">
				MES는 생산관리 시스템 으로 기업의 생산 현장에서
				<br v-if="isMiniMobile" />작업일정, 작업지시, 품질관리, 작업 실적 집계
				등 <br />
				제반 활동을 지원하기 위한 관리 시스템 입니다.
			</p>
		</div>

		<div class="sub12_con02_stitle">
			<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> MES 특장점
		</div>

		<div class="sub12_con02">
			<ul>
				<li><img :src="require('@/assets/image/sub21_con02-1.png')" /></li>
				<li><img :src="require('@/assets/image/sub21_con02-2.png')" /></li>
				<li><img :src="require('@/assets/image/sub21_con02-3.png')" /></li>
				<li><img :src="require('@/assets/image/sub21_con02-4.png')" /></li>
			</ul>
		</div>
	</div>

	<div id="sub21_con03_warp">
		<div
			class="sub21_con03_title"
			:data-aos="isMiniMobile ? '' : 'fade-down'"
			data-aos-offset="300"
			data-aos-duration="500"
		>
			<p class="sub21_text09">EMS</p>
			<p class="sub21_text06">Environmental Management System</p>
			<p class="sub21_text02">
				EMS는 기업의 설비 효율 향상을 위한 <br v-if="isMiniMobile" />
				개선 활동을 통합적이며 체계적으로<br />
				지원할 수 있는 설비 관리 시스템입니다.
			</p>
		</div>
		<div class="sub12_con02_stitle">
			<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> EMS 특장점
		</div>
		<div class="sub12_con02">
			<ul>
				<li><img :src="require('@/assets/image/sub21_con03-1.png')" /></li>
				<li><img :src="require('@/assets/image/sub21_con03-2.png')" /></li>
				<li><img :src="require('@/assets/image/sub21_con03-3.png')" /></li>
				<li><img :src="require('@/assets/image/sub21_con03-4.png')" /></li>
			</ul>
		</div>
	</div>

	<div id="sub21_con04_warp">
		<div
			class="sub21_con04_title"
			:data-aos="isMiniMobile ? '' : 'fade-down'"
			data-aos-offset="300"
			data-aos-duration="500"
		>
			<p class="sub21_text10">스마트팩토리 기대효과</p>
			<p class="sub21_text12">
				스마트한 공장, 효율적인 관리! 관리 사각지대 감소로
				<br v-if="isMiniMobile" />
				효율적인 업무관리가 가능합니다.
			</p>
		</div>
		<div v-if="isMiniMobile">
			<div class="factory_benefits_icons">
				<ul class="factory_icons_list">
					<ul class="factory_icons_list_col">
						<li class="factory_icon">
							<img
								:src="require('@/assets/image/smartFactory_icons01.png')"
								alt="생산성 향상 아이콘"
							/>
						</li>
						<li class="factory_icon">
							<img
								:src="require('@/assets/image/smartFactory_icons02.png')"
								alt="품질 향상 아이콘"
							/>
						</li>
						<li class="factory_icon">
							<img
								:src="require('@/assets/image/smartFactory_icons03.png')"
								alt="원가 감소"
							/>
						</li>
					</ul>
					<ul class="factory_icons_list_col">
						<li class="factory_icon">
							<img
								:src="require('@/assets/image/smartFactory_icons04.png')"
								alt="납기 준수"
							/>
						</li>
						<li class="factory_icon">
							<img
								:src="require('@/assets/image/smartFactory_icons05.png')"
								alt="매출액 증가"
							/>
						</li>
					</ul>
				</ul>
			</div>
		</div>
		<div class="sub21_con04">
			<img
				v-if="!isMiniMobile"
				:src="require('@/assets/image/sub21_con04-1.png')"
			/>
			<ul class="benefits_desc_con">
				<ul class="benefits_desc_con_col">
					<li>
						<div class="sub21_con04_left">01</div>
						<div class="sub21_con04_right">
							모바일과 웹을 활용한 Paperwork less,
							<br v-if="isMiniMobile" />APP을 활용한<br v-if="!isMiniMobile" />
							라인에서의 즉각적 업무
						</div>
					</li>
					<li>
						<div class="sub21_con04_left">02</div>
						<div class="sub21_con04_right02">
							현장정보 와 사용자 실시간 파악 및 관리,
							<br v-if="isMiniMobile" />문제 확인 및 대응력
						</div>
					</li>
				</ul>
				<ul class="benefits_desc_con_col">
					<li>
						<div class="sub21_con04_left">03</div>
						<div class="sub21_con04_right02" id="sub21_con04_right02_padding">
							기간, 분기 별 통계 및 KPI, 성과 파악
						</div>
					</li>
					<li>
						<div class="sub21_con04_left">04</div>
						<div class="sub21_con04_right02">
							생산공정의 불합리 요소 제거 <br v-if="isMiniMobile" />( 품질,
							생산성, 원가 측면)
						</div>
					</li>
				</ul>
			</ul>
		</div>
	</div>
	<div id="sub21_con05_warp">
		<div
			class="sub21_con05_title"
			:data-aos="isMiniMobile ? '' : 'fade-down'"
			data-aos-offset="300"
			data-aos-duration="500"
		>
			<p class="sub21_text11">스마트 팩토리 5대 요건</p>
			<p class="sub21_text02">
				아래는 스마트공장을 구성하고 수준별로 발전시킴에 있어서
				<br v-if="isMiniMobile" />
				꼭 필요한 다섯 가지 조건입니다.
			</p>
		</div>
		<div class="sub21_con05">
			<ul>
				<li>
					<div class="sub21_con05_left">01</div>
					<div class="sub21_con05_right">
						<p class="sub21_text13">4M+1E의 디지털화</p>
						<p class="sub21_text14">
							4M+1E의 각 요소(Man, Machinery, Material,
							<br v-if="isMiniMobile" />
							Mathod, Environment)들을 실시간으로 디지털값을
							<br v-if="isMiniMobile" />
							인지하고, 측정가능한 정보를 제공해야 하며 통신을 통해
							<br v-if="isMiniMobile" />
							대화가 가능해야 함
						</p>
					</div>
				</li>
				<li>
					<div class="sub21_con05_left">02</div>
					<div class="sub21_con05_right">
						<p class="sub21_text13">지능화</p>
						<p class="sub21_text14">
							알고리즘 또는 인공지능 등의 솔루션을 이용,
							<br v-if="isMiniMobile" />
							최적화 또는 예측가능한 결과를 제공해야 함
						</p>
					</div>
				</li>
				<li>
					<div class="sub21_con05_left">03</div>
					<div class="sub21_con05_right">
						<p class="sub21_text13">통합</p>
						<p class="sub21_text14">
							사회망과 가치사슬을 통해 정보 교류가 이뤄지도록 하는
							<br v-if="isMiniMobile" />
							수평적 통합과 최하위 수준인 기계장치부터
							<br v-if="isMiniMobile" />
							기업 비즈니스 수준까지 수직적 통합을 지향해야 함
						</p>
					</div>
				</li>
				<li>
					<div class="sub21_con05_left">04</div>
					<div class="sub21_con05_right">
						<p class="sub21_text13">엔지니어링 지식의 창출</p>
						<p class="sub21_text14">
							지속해서 정보를 확보하고 저장한 후,
							<br v-if="isMiniMobile" />
							이를 바탕으로 자동화를 위한 제조 지식을
							<br v-if="isMiniMobile" />
							점진적으로 창출할 수 있어야 함
						</p>
					</div>
				</li>
				<li>
					<div class="sub21_con05_left">05</div>
					<div class="sub21_con05_right">
						<p class="sub21_text13">스마트 시스템과의 연결</p>
						<p class="sub21_text14">
							향후 발전에 스마트 제품들과 통신 표준에 의거해
							<br v-if="isMiniMobile" />
							연결이 가능해야 함
						</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isMiniMobile = ref(window.innerWidth <= 435);

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	window.addEventListener('resize', onResize);

	onResize();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style>
.sub21_con02_title {
	width: 100%;
	text-align: center;
}

.sub21_con03_title {
	width: 100%;
	text-align: center;
}

.sub12_con02_stitle {
	width: 1300px;
	margin: 0 auto;
	padding-top: 80px;
	font-size: 30px;
	font-weight: bold;
}

.sub12_con02 {
	width: 1300px;
	margin: 0 auto;
	padding-top: 30px;
}

.sub12_con02 ul {
	padding-bottom: 40px;
}

.sub12_con02 ul li {
	width: 50%;
	float: left;
	margin-top: 40px;
}

.mes_container {
	height: 1000px;
}

#sub21_con03_warp {
	clear: both;
	width: 100%;
	height: 1100px;
	background: #f7f7f7;
	padding-top: 10px;
	margin-top: 30px;
}

.sub21_text02 {
	font-size: 18px;
	line-height: 26px;
	padding-top: 20px;
}

.sub21_text06 {
	font-size: 17px;
	line-height: 24px;
	padding-top: 10px;
	color: #a7a7a7;
}

.sub21_text07 {
	font-size: 22px;
	line-height: 30px;
	padding-top: 10px;
	color: #a7a7a7;
}

.sub21_text08 {
	margin-top: 50px;
	font-size: 50px;
	letter-spacing: -1px;
	font-weight: bold;
}

.sub21_text10 {
	font-size: 50px;
	letter-spacing: -1px;
	font-weight: bold;
	color: #fff;
}

.sub21_text09 {
	margin-top: 50px;
	font-size: 50px;
	letter-spacing: -1px;
	font-weight: bold;
}

.sub21_text11 {
	margin-top: 40px;
	font-size: 50px;
	letter-spacing: -1px;
	font-weight: bold;
}

.sub21_text12 {
	font-size: 18px;
	line-height: 26px;
	padding-top: 20px;
	color: #fff;
}

.sub21_text13 {
	font-size: 30px;
	font-weight: bold;
}

.sub21_text14 {
	font-size: 20px;
	line-height: 28px;
	padding-top: 20px;
	padding-right: 35px;
}

#sub21_con04_warp {
	width: 100%;
	height: 960px;
	background: url('/src/assets/image/sub21_con04_bg.png') center top;
	background-size: cover;
}

.sub21_con04_title {
	width: 100%;
	text-align: center;
	padding-top: 100px;
}

.sub21_con04 {
	width: 1140px;
	margin: 0 auto;
	padding-top: 60px;
}

.sub21_con04 ul {
	padding-top: 20px;
	display: flex;
	flex-direction: column;
}

.sub21_con04 ul ul {
	display: flex;
	flex-direction: row;
}

.sub21_con04 ul li {
	width: 540px;
	height: 85px;
	border: 1px solid #ccc;
	border-radius: 50px;
	margin-top: 20px;
	margin: 0 15px;
}

.sub21_con04_left {
	float: left;
	font-size: 20px;
	font-weight: bold;
	color: #fff;
	padding-top: 25px;
	padding-left: 40px;
}

.sub21_con04_right {
	float: left;
	font-size: 18px;
	line-height: 24px;
	color: #fff;
	padding-left: 22px;
	padding-top: 16px;
}

.sub21_con04_right02 {
	float: left;
	font-size: 18px;
	line-height: 24px;
	color: #fff;
	padding-left: 22px;
	padding-top: 26px;
}

.sub21_con01_right {
	width: 55%;
	left: 40%;
	float: left;
	padding-left: 60px;
}

.sub21_con01_right01 {
	width: 100%;
	padding-top: 20px;
}

.sub21_con01_right02 {
	width: 100%;
	padding-top: 30px;
}

.sub21_con02_title {
	width: 100%;
	text-align: center;
}

.sub21_con03_title {
	width: 100%;
	text-align: center;
}

.sub12_con02_stitle {
	width: 1300px;
	margin: 0 auto;
	padding-top: 80px;
	font-size: 30px;
	font-weight: bold;
}

.sub12_con02 {
	width: 1300px;
	margin: 0 auto;
	padding-top: 30px;
}

.sub12_con02 ul li {
	width: 50%;
	float: left;
	margin-top: 40px;
}

#sub21_con03_warp {
	clear: both;
	width: 100%;
	height: 1100px;
	background: #f7f7f7;
	padding-top: 10px;
	margin-top: 30px;
}

#sub21_con04_warp {
	width: 100%;
	height: 960px;
	background: url('/src/assets/image/sub21_con04_bg.png') center top;
	background-size: cover;
}

.sub21_con04 {
	width: 1140px;
	margin: 0 auto;
	padding-top: 60px;
}

.sub21_con04 ul {
	padding-top: 40px;
}

.sub21_con04_left {
	float: left;
	font-size: 20px;
	font-weight: bold;
	color: #fff;
	padding-top: 25px;
	padding-left: 40px;
}

.sub21_con04_right {
	float: left;
	font-size: 18px;
	line-height: 24px;
	color: #fff;
	padding-left: 22px;
	padding-top: 16px;
}

.sub21_con04_right02 {
	float: left;
	font-size: 18px;
	line-height: 24px;
	color: #fff;
	padding-left: 22px;
	padding-top: 26px;
}

#sub21_con05_warp {
	width: 100%;
	padding-top: 40px;
}

.sub21_con05_title {
	width: 100%;
	text-align: center;
}

.sub21_con05 {
	width: 997px;
	margin: 0 auto;
}

.sub21_con05_left {
	float: left;
	width: 70px;
	height: 60px;
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	background: #004b96;
	color: #fff;
	padding-top: 14px;
	margin-left: 40px;
	margin-top: 40px;
}

.sub21_con05_right {
	/* float: left; */
	margin-left: 30px;
	margin-top: 40px;
	width: 100%;
}

.sub21_con05 ul {
	margin-top: 80px;
}

.sub21_con05 ul li {
	width: 997px;
	height: 205px;
	background: #fafafa;
	border: 1px solid #e9e9e9;
	margin-top: 40px;
	display: flex;
}

@media screen and (max-width: 435px) {
	.sub21_text08,
	.sub21_text09 {
		margin-top: 50px;
		font-size: 30px;
		letter-spacing: -1px;
		font-weight: bold;
	}

	.sub12_con02_stitle {
		width: 100%;
		margin: 0 auto;
		padding-left: 10px;
		padding-top: 80px;
		font-size: 22px;
		font-weight: bold;
	}

	.sub12_con02 ul {
		display: flex;
		flex-direction: column;
		width: 100vw;
	}

	.sub12_con02 ul li {
		width: fit-content;
	}

	.sub12_con02 ul li img {
		width: 100%;
	}

	.mes_container {
		height: 100%;
	}

	.sub21_text10 {
		font-size: 30px;
		letter-spacing: -1px;
		font-weight: bold;
		color: #fff;
		margin-top: 50px;
	}

	#sub21_con04_warp {
		width: 100%;
		height: 1000px;
		background: url('/src/assets/image/sub21_con04_bg.png') center top;
		background-size: cover;
	}

	.factory_benefits_icons {
		margin-top: 10px;
		width: 100%;
	}

	.factory_icons_list {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.factory_icon img {
		width: 100px;
		margin-top: 20px;
	}

	.factory_icons_list_col {
		display: flex;
		text-align: center;
		width: 100%;
		margin: 0 auto;
		gap: 20px;
		justify-content: center;
	}

	.benefits_desc_con {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: 0 !important;
	}

	.sub21_con04 ul li {
		width: fit-content;
		float: left;
		height: 85px;
		border: 1px solid #ccc;
		border-radius: 50px;
		margin-top: 20px;
		margin: 20px 15px;
	}

	.sub21_con04 ul li {
		width: 350px;
		height: 55px;
		border: 1px solid #ccc;
		border-radius: 50px;
		margin-top: 20px;
		margin: 20px 15px;
		display: flex;
	}

	.sub21_con04_left {
		font-size: 20px;
		font-weight: bold;
		color: #fff;
		padding-top: 14px;
		padding-left: 20px;
	}

	.sub21_con04_right {
		font-size: 16px;
		line-height: 18px;
		color: #fff;
		padding-left: 15px;
		padding-top: 9px;
	}

	.sub21_con04_right02 {
		font-size: 16px;
		line-height: 18px;
		color: #fff;
		padding-left: 15px;
		padding-top: 9px;
		height: 100%;
	}

	#sub21_con04_right02_padding {
		padding-top: 17px;
	}

	.sub21_con04 {
		padding-top: 20px;
	}
	.sub21_text11 {
		font-size: 30px;
		letter-spacing: -1px;
		font-weight: bold;
	}

	.sub21_text12 {
		font-size: 15px;
		line-height: 26px;
		padding-top: 20px;
		color: #fff;
	}

	.sub21_con05_left {
		width: 45px;
		height: 45px;
		font-size: 13px;
		font-weight: bold;
		text-align: center;
		background: #004b96;
		color: #fff;
		padding-top: 15px;
		margin-left: 20px;
		margin-top: 20px;
	}

	.sub21_con05_right {
		margin-left: 20px;
		margin-top: 20px;
	}

	.sub21_con05 ul li {
		width: 100vw;
		height: 260px;
		background: #fafafa;
		border: 1px solid #e9e9e9;
		margin-top: 40px;
		display: flex;
		flex-direction: column;
	}

	.sub21_text13 {
		font-size: 20px;
		font-weight: bold;
	}

	.sub21_text14 {
		font-size: 15px;
		line-height: 28px;
		padding-top: 20px;
	}

	.benefits_desc_con_col {
		display: flex;
		flex-direction: column !important;
	}

	.sub21_con04 ul {
		padding-top: 10px;
	}
}
</style>
