<template>
	<div id="main_con02_warp">
		<div class="main_benefits_title">
			<p
				class="main_con01_text01"
				:data-aos="isMiniMobile ? '' : 'fade-up'"
				data-aos-duration="1000"
			>
				BUSINSESS BENEFIT
			</p>
			<p
				class="main_con01_text02"
				:data-aos="isMiniMobile ? '' : 'fade-up'"
				data-aos-delay="500"
				data-aos-duration="1000"
			>
				스마트팩토리 도입 시
				<span style="font-weight: bold">관리 사각지대 감소</span>로<br />
				<span style="font-weight: bold">효율적인 업무관리</span>가 가능합니다.
			</p>
		</div>

		<div class="main_con02">
			<ul id="main_con02_list" v-if="isMiniMobile">
				<ul class="main_con02_list_col1">
					<li>
						<img :src="require('@/assets/image/main_con02-1.png')" />
						<p class="main_con02_text01">
							생산성<br v-if="isMiniMobile" />
							향상
						</p>
						<div class="main_con02_data" style="left: 8px">
							<CountUp
								:start-val="0"
								:end-val="30"
								:duration="3"
								v-if="isCountVisible && !isMiniMobile"
								class="main_con02_text02"
							>
							</CountUp>
							<div v-if="isMiniMobile" class="main_con02_text02">30</div>
							<span class="main_con02_text03">%</span>
						</div>
					</li>
					<li>
						<img :src="require('@/assets/image/main_con02-2.png')" />
						<p class="main_con02_text01">품질<br v-if="isMiniMobile" />향상</p>
						<div class="main_con02_data" style="left: -1px">
							<CountUp
								:start-val="0"
								:decimalPlaces="1"
								:end-val="43.5"
								:duration="3"
								v-if="isCountVisible && !isMiniMobile"
								class="main_con02_text02"
							></CountUp>
							<div v-if="isMiniMobile" class="main_con02_text02">43.5</div>
							<span class="main_con02_text03">%</span>
						</div>
					</li>
					<li>
						<img :src="require('@/assets/image/main_con02-3.png')" />
						<p class="main_con02_text01">
							매출액 <br v-if="isMiniMobile" />증가
						</p>
						<div class="main_con02_data" style="left: 7px">
							<CountUp
								:start-val="0"
								:decimalPlaces="1"
								:end-val="7.7"
								:duration="3"
								class="main_con02_text02"
								v-if="isCountVisible && !isMiniMobile"
							></CountUp>
							<div v-if="isMiniMobile" class="main_con02_text02">7.7</div>
							<span class="main_con02_text03">%</span>
						</div>
					</li>
				</ul>
				<ul class="main_con02_list_col2">
					<li>
						<img :src="require('@/assets/image/main_con02-4.png')" />
						<p class="main_con02_text01">원가 <br v-if="isMiniMobile" />감소</p>
						<div class="main_con02_data" style="left: -8px">
							<CountUp
								:start-val="0"
								:decimalPlaces="1"
								:end-val="-15.9"
								:duration="3"
								class="main_con02_text02"
								v-if="isCountVisible && !isMiniMobile"
							></CountUp>
							<div v-if="isMiniMobile" class="main_con02_text02">-15.9</div>
							<span class="main_con02_text03">%</span>
						</div>
					</li>
					<li>
						<img :src="require('@/assets/image/main_con02-5.png')" />
						<p class="main_con02_text01">
							납기 <br v-if="isMiniMobile" />준수율
						</p>
						<div class="main_con02_data">
							<CountUp
								:start-val="0"
								:decimalPlaces="1"
								:end-val="15.5"
								:duration="3"
								class="main_con02_text02"
								v-if="isCountVisible && !isMiniMobile"
							></CountUp>
							<div v-if="isMiniMobile" class="main_con02_text02">15.5</div>
							<span class="main_con02_text03">%</span>
						</div>
					</li>
					<li>
						<img :src="require('@/assets/image/main_con02-6.png')" />
						<p class="main_con02_text01">
							산업재해 <br v-if="isMiniMobile" />감소
						</p>
						<div class="main_con02_data" style="left: -5px">
							<CountUp
								:start-val="0"
								:decimalPlaces="1"
								:end-val="-18.3"
								:duration="3"
								class="main_con02_text02"
								v-if="isCountVisible && !isMiniMobile"
							>
							</CountUp>
							<div v-if="isMiniMobile" class="main_con02_text02">-18.3</div>
							<span class="main_con02_text03">%</span>
						</div>
					</li>
				</ul>
			</ul>
			<ul id="main_con02_list" v-if="!isMiniMobile">
				<li>
					<img :src="require('@/assets/image/main_con02-1.png')" />
					<p class="main_con02_text01">생산성 향상</p>
					<div class="main_con02_data">
						<CountUp
							:start-val="0"
							:end-val="30"
							:duration="3"
							v-if="isCountVisible"
							class="main_con02_text02"
						>
						</CountUp>
						<span class="main_con02_text03">%</span>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/image/main_con02-2.png')" />
					<p class="main_con02_text01">품질 향상</p>
					<div class="main_con02_data">
						<CountUp
							:start-val="0"
							:decimalPlaces="1"
							:end-val="43.5"
							:duration="3"
							v-if="isCountVisible"
							class="main_con02_text02"
						></CountUp>
						<span class="main_con02_text03">%</span>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/image/main_con02-3.png')" />
					<p class="main_con02_text01">매출액 증가</p>
					<div class="main_con02_data">
						<CountUp
							:start-val="0"
							:decimalPlaces="1"
							:end-val="7.7"
							:duration="3"
							class="main_con02_text02"
							v-if="isCountVisible"
						></CountUp>
						<span class="main_con02_text03">%</span>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/image/main_con02-4.png')" />
					<p class="main_con02_text01">원가 감소</p>
					<div class="main_con02_data">
						<CountUp
							:start-val="0"
							:decimalPlaces="1"
							:end-val="-15.9"
							:duration="3"
							class="main_con02_text02"
							v-if="isCountVisible"
						></CountUp>

						<span class="main_con02_text03">%</span>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/image/main_con02-5.png')" />
					<p class="main_con02_text01">납기 준수율</p>
					<div class="main_con02_data">
						<CountUp
							:start-val="0"
							:decimalPlaces="1"
							:end-val="15.5"
							:duration="3"
							class="main_con02_text02"
							v-if="isCountVisible"
						></CountUp>

						<span class="main_con02_text03">%</span>
					</div>
				</li>
				<li>
					<img :src="require('@/assets/image/main_con02-6.png')" />
					<p class="main_con02_text01">산업 재해 감소</p>
					<div class="main_con02_data">
						<CountUp
							:start-val="0"
							:decimalPlaces="1"
							:end-val="-18.3"
							:duration="3"
							class="main_con02_text02"
							v-if="isCountVisible"
						>
						</CountUp>

						<span class="main_con02_text03">%</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

import CountUp from 'vue-countup-v3';

const scrollTop = ref(0);
const isCountVisible = ref(false);

const isMiniMobile = ref(window.innerWidth <= 435);

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

function handleCountUpStart() {
	scrollTop.value = document.documentElement.scrollTop;

	if (scrollTop.value > 300) {
		isCountVisible.value = true;
	}
}

function handleCountUpEnd() {
	scrollTop.value = document.documentElement.scrollTop;

	isCountVisible.value = false;
}

onMounted(() => {
	const observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					handleCountUpStart(); // 요소가 보일 때 handleCountUpStart 실행
				} else {
					handleCountUpEnd(); // 요소가 보이지 않을 때 handleCountUpEnd 실행
				}
			});
		},
		{ threshold: 0.1 },
	);

	const targetElement = document.querySelector('.main_con02_text01');
	observer.observe(targetElement);

	window.addEventListener('resize', onResize);

	onResize();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style>
#main_con02_warp {
	position: relative;
	width: 100%;
	height: 100vh;
	background: url('/src/assets/image/main_con02_bg.png') center top;
	background-size: cover;
}

.main_benefits_title {
	text-align: center;
	margin-top: 7rem;
}

.main_con02 {
	width: 100%;
	margin: 0 auto;
	padding-top: 50px;
}

.main_con02_data {
	display: flex;
	width: 200px;
	margin: 0 auto;
	justify-content: center;
	text-align: center;
	padding-top: 10px;
}
#main_con02_list {
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: center;
	margin: 0 auto;
	justify-content: center;
}

.main_con02 ul li {
	width: 14.5%;
	height: 10%;
	border-left: 1px solid #019495;
	text-align: center;
	padding-top: 50px;
}

.main_con02 ul li:first-child {
	border-left: 0px;
}

.main_con02_text01 {
	font-size: 1.2rem;
	margin-top: 5px;
	color: #fff;
	font-weight: bold;
}

.main_con01_text01 {
	font-size: 18px;
	font-weight: bold;
	color: #fff;
}

.main_con01_text02 {
	font-size: 45px;
	line-height: 62px;
	color: #fff;
	padding-top: 30px;
}

.main_con02_text02 {
	font-size: 4rem;
	font-weight: bold;
	color: #24d2dc;
	text-align: center;
	padding-top: 30px;
}

.main_con02_text03 {
	position: relative;
	left: 15px;
	font-size: 23px;
	color: #24d2dc;
	padding-top: 30px;
	text-align: center;
	padding-left: 1px;
}

.benefits-numbers-wrapper {
	display: flex;
	justify-content: center;
}

.benefit-numbers {
	font-weight: bold;
	font-size: 45px;
	text-align: center;
	margin: 20px;
}

@media screen and (max-width: 435px) {
	.main_con02 ul {
		width: 100%;
		margin: 0 auto;
	}
	.main_con02 ul ul li {
		width: 13%;
		height: 10%;
		border: none;
		text-align: center;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
	}

	.main_con02_list_col1,
	.main_con02_list_col2 {
		display: flex;
		flex-direction: row;
	}
	.main_con02 ul ul li img {
		width: 100%;
		margin: 0 auto;
		margin-left: 2px;
	}

	.main_con02_data {
		position: relative;
		justify-content: left;
	}

	.main_con02_text01 {
		text-align: center;
		width: 100%;
		margin: 0 auto;
		font-size: 0.8rem;
		font-weight: 500;
	}

	.main_con02_text02 {
		font-size: 1rem;
		line-height: 24px;
		color: #fff;
		margin: 0;
		padding: 0;
		position: relative;
		top: 5px;
	}

	.main_con02_text03 {
		font-size: 12px;
		color: #24d2dc;
		margin-left: 2px;
		padding: 0;
		position: static;
		margin-top: 10px;
	}

	.main_con02 {
		width: 100%;
		margin: 0 auto;
		padding-top: 0px;
	}
	#main_con02_warp {
		height: 630px;
	}
	#main_con02_list {
		display: flex;
		flex-direction: column !important;
	}

	.main_con01_text02 {
		font-size: 20px;
		line-height: 25px;
		color: #fff;
		padding-top: 30px;
	}
}
</style>
