<template>
	<div class="scroll-line-container">
		<ul class="scroll-down-ui">
			<li class="scroll-line"></li>
			<li class="scroll-down-text">
				<div>Scroll</div>
				<div>down</div>
			</li>
		</ul>
		<div></div>
	</div>
</template>

<script setup></script>

<style>
.scroll-line-container {
	color: white;
	position: relative;
	left: 5%;
	bottom: 10%;
	display: flex;
	flex-direction: column;
	z-index: 10;
	overflow-y: hidden;
	width: 200px;
}

.scroll-down-ui {
	width: 100px;
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 20px;
	display: flex;
}

.scroll-down-text {
	margin-top: 2px;
}

.scroll-line {
	position: relative;
	padding-right: 10px;
	height: 45px;
	border-left: 3px solid rgba(255, 255, 255, 0.2);
}

.scroll-line::before {
	content: '';
	position: absolute;
	left: -3px;
	top: 0;
	bottom: 0;
	width: 3px; /* 테두리 두께 */
	height: 20px;

	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0)
	);
	background-size: 2px 100%;
	animation:
		flow 2s infinite ease-in-out,
		shimmer 1.5s infinite linear;
}

@keyframes flow {
	0% {
		top: 0;
	}
	100% {
		top: 55%;
	}
}

@keyframes shimmer {
	0% {
		background-position: top;
	}
	100% {
		background-position: bottom;
	}
}

@media screen and (max-width: 768px) {
	.scroll-down-text {
		font-size: 10px;
	}

	.scroll-line {
		height: 30px;
	}
}

@media screen and (max-width: 435px) {
	.scroll-line-container {
		color: white;
		position: absolute;
		display: flex;
		flex-direction: column;
		z-index: 10;
		overflow-y: hidden;
		width: 200px;
	}
}
</style>
