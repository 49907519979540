<template>
	<div>
		<div class="sub41_con_title" data-aos="fade-down" data-aos-duration="1000">
			<p class="sub41_text01">SOLUTION</p>
		</div>
		<div
			data-aos="fade-up"
			data-aos-duration="1000"
			class="sub41_con01"
			@mouseover="changeHoveredState(0, true)"
			@mouseleave="changeHoveredState(0, false)"
		>
			<div class="sub41_con01_left">
				<img :src="require('@/assets/image/solution_AI-OCR.png')" />
			</div>
			<div :class="hoverStates[0] ? 'sub41_con01_right' : 'sub41_con02_right'">
				<div>
					<p :class="hoverStates[0] ? 'sub41_text02' : 'sub41_text06'">
						DX - AI OCR 솔루션
					</p>
					<p :class="hoverStates[0] ? 'sub41_text03' : 'sub41_text07'">
						Optical Character Recognition
					</p>
				</div>
				<div :class="hoverStates[0] ? 'sub41_text04' : 'sub41_text08'">
					Deep Learning 알고리즘을 사용하여<br />
					정확성과 기능이 향상된 OCR <br />
					학습을 통한 다양한 환경에서의 높은 <br />정확도와 지속적인 향상
					<br />
					인공지능을 활용한 데이터 판별
				</div>
				<div :class="hoverStates[0] ? 'sub41_text05' : 'sub41_text09'">
					<router-link to="/solutionDetail/ai-ocr">More view +</router-link>
				</div>
			</div>
		</div>

		<div style="clear: both"></div>

		<div
			:data-aos="isMiniMobile ? '' : 'fade-up'"
			data-aos-duration="1000"
			class="sub41_con02"
			@mouseover="changeHoveredState(1, true)"
			@mouseleave="changeHoveredState(1, false)"
		>
			<div class="sub41_con02_left">
				<img :src="require('@/assets/image/solution_DX-SIGN.png')" />
			</div>
			<div :class="hoverStates[1] ? 'sub41_con01_right' : 'sub41_con02_right'">
				<div>
					<p :class="hoverStates[1] ? 'sub41_text02' : 'sub41_text06'">
						전자서명 시스템 DX SIGN
					</p>
					<p :class="hoverStates[1] ? 'sub41_text03' : 'sub41_text07'">
						Digital Signature System
					</p>
				</div>
				<div :class="hoverStates[1] ? 'sub41_text04' : 'sub41_text08'">
					편리한 문서편집과 서명기능<br />
					대량으로 서명 요청 기능
					<br />
					템플릿과 계약서 관리
					<br />
					원본 검증 키값 생성
					<br />
					추가 암호 설정 기능 <br />
					<!-- 공간 차지로 UI 깨짐, 숨김처리
						SQMS 생성 및 관리 <br />
					ECQ / ECQR 생성 및 관리 <br />
					Check Sheet 관리 및 점검사항 입력 <br />
					생산 제조 관리 공정 별 수치 모니터링 <br />
					공정, 설비, 라인 별 현황 관리 <br /> -->
				</div>
				<div :class="hoverStates[1] ? 'sub41_text05' : 'sub41_text09'">
					<RouterLink to="/solutionDetail/dxsign">More view +</RouterLink>
				</div>
			</div>
		</div>

		<div style="clear: both"></div>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isMiniMobile = ref(window.innerWidth <= 435);

const hoverStates = ref([false, false, false, false, false]);

function changeHoveredState(index, isHovered) {
	hoverStates.value[index] = isHovered;
}

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	window.addEventListener('resize', onResize);

	onResize();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style></style>
