<template>
	<div>
		<div class="sub_title_badge_container">
			<div class="sub_title_badge">DX SIGN</div>
		</div>
		<div class="sub42_con02">
			<div class="solution_sub_title">
				간편하고 안전한 전자계약 서명 서비스 DX SIGN
			</div>
			<div class="solution_sub_title_img">
				<img :src="require('@/assets/image/sign/title.png')" />
			</div>
			<div v-if="isMobile" class="solution_content_background">
				<p>편리한 문서편집, 서명기능과 대량 서명 전송 기능</p>
				<p>시공간 제약 없이 계약서 생성부터 관리까지 모든</p>
				<p>계약을 관리 하는 간편하고 효율적인</p>
				<p>전자계약 서명 서비스 DX SIGN</p>
			</div>
			<div v-else class="solution_content_background">
				<p>편리한 문서편집, 서명기능과 대량 서명 전송 기능</p>
				<p>시공간 제약 없이 계약서 생성부터 관리까지 모든 계약을 관리 하는</p>
				<p>간편하고 효율적인 전자계약 서명 서비스 DX SIGN</p>
			</div>
		</div>
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" />
				특장점
			</div>
			<div class="features_container">
				<div>
					<div>
						<img :src="require('@/assets/image/sign/feature1.png')" />
					</div>
					<div>
						<p>편리한 문서 편집과</p>
						<p>서명 기능</p>
					</div>
				</div>
				<div>
					<div>
						<img :src="require('@/assets/image/sign/feature2.png')" />
					</div>
					<div>
						<p>시간과 공간 제약 없는 간편한</p>
						<p>전자계약으로 업무 효율 증가</p>
					</div>
				</div>
				<div>
					<div>
						<img :src="require('@/assets/image/sign/feature3.png')" />
					</div>
					<div>
						<p>모든 계약관리를 손쉽게</p>
						<p>취합하여 관리 효율 증가</p>
					</div>
				</div>
				<div>
					<div>
						<img :src="require('@/assets/image/sign/feature4.png')" />
					</div>
					<div>
						<p>계약 체결 비용과</p>
						<p>관리비용의 절감 효과</p>
					</div>
				</div>
			</div>
		</div>
		<div class="sub42_con02 sign_process_container">
			<div v-if="isMobile">
				<p>효율적인 계약 업무를 위한</p>
				<p>주요기능만 쏙쏙 담은 DX - SIGN</p>
			</div>
			<div v-else>효율적인 계약 업무를 위한 주요기능만 쏙쏙 담은 DX - SIGN</div>
			<div v-if="isMobile">
				<div class="hexagon">
					<img :src="require('@/assets/image/sign/hexagon_inner.png')" />
				</div>
				<div class="feature_box"><p>서명요청</p></div>
				<div class="feature_box"><p>계약서 편집</p></div>
				<div class="feature_box"><p>대량 서명 요청</p></div>
				<div class="feature_box"><p>계약서 관리</p></div>
				<div class="feature_box"><p>템플릿 관리</p></div>
				<div class="feature_box"><p>외부문서 관리</p></div>
				<div class="feature_box"><p>감사 추적 인증서</p></div>
				<div class="feature_box"><p>첨부파일 기능</p></div>
				<div class="feature_box"><p>카테고리 기능</p></div>
				<div class="feature_box"><p>추가 암호 기능</p></div>
			</div>
			<div v-else>
				<div>
					<div class="feature_box"><p>서명요청</p></div>
					<div class="feature_box"><p>계약서 편집</p></div>
					<div class="feature_box"><p>대량 서명 요청</p></div>
					<div class="feature_box"><p>계약서 관리</p></div>
					<div class="feature_box"><p>템플릿 관리</p></div>
				</div>
				<div class="hexagon">
					<img :src="require('@/assets/image/sign/hexagon_inner.png')" />
				</div>
				<div>
					<div class="feature_box"><p>외부문서 관리</p></div>
					<div class="feature_box"><p>감사 추적 인증서</p></div>
					<div class="feature_box"><p>첨부파일 기능</p></div>
					<div class="feature_box"><p>카테고리 기능</p></div>
					<div class="feature_box"><p>추가 암호 기능</p></div>
				</div>
			</div>
			<div>
				<img :src="require('@/assets/image/sign/arrow.png')" />
			</div>
			<div v-if="isMobile">
				<p>간편한 서명 프로세스로</p>
				<p>손쉽게 계약을 진행해보세요!</p>
			</div>
			<div v-else>간편한 서명 프로세스로 손쉽게 계약을 진행해보세요!</div>
			<div v-if="!isMobile">
				<img :src="require('@/assets/image/sign/under_line.png')" />
			</div>
			<div>
				<div class="process_box">
					<div>01</div>
					<div>계약서 업로드</div>
					<div>
						<p>서명을 진행할</p>
						<p>계약서 업로드</p>
					</div>
				</div>
				<div class="process_box">
					<div>02</div>
					<div>서명위치 지정</div>
					<div>
						<p>계약서 편집 및</p>
						<p>서명 위치 지정</p>
					</div>
				</div>
				<div class="process_box">
					<div>03</div>
					<div>계약서 발송</div>
					<div>
						<p>편집한 계약서</p>
						<p>서명자에게 발송</p>
					</div>
				</div>
				<div class="process_box">
					<div>04</div>
					<div>수신자 서명진행</div>
					<div>
						<p>수신된 계약서</p>
						<p>확인 및 서명 진행</p>
					</div>
				</div>
				<div v-if="isMobile">
					<div class="process_box">
						<div>05</div>
						<div>계약서 서명완료</div>
						<div>
							<p>서명 완료 시 계약서 및</p>
							<p>감사 추적 인증서 발송</p>
							<p>법적 효력 발생</p>
						</div>
					</div>
				</div>
				<div v-else class="process_box">
					<div>05</div>
					<div>계약서 서명완료</div>
					<div>
						<p>서명 완료 시 계약서 및</p>
						<p>감사 추적 인증서 발송</p>
						<p>법적 효력 발생</p>
					</div>
				</div>
			</div>
		</div>
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" />
				적용 분야
			</div>
			<div class="solution_content_list">
				<div>
					<div>1</div>
					<div>
						<p>기업계약</p>
						<small>근로계약서, 연봉계약서, 프리랜서 업무 계약서 등</small>
					</div>
				</div>
				<div>
					<div>2</div>
					<div>
						<p>신청서</p>
						<small>회원가입 신청서, 휴가 신청서, 근로 신청서 등</small>
					</div>
				</div>
				<div>
					<div>3</div>
					<div>
						<p>동의서</p>
						<small
							>개인정보 활용 동의서, 서비스 이용 동의서, 제 3자 동의서 등</small
						>
					</div>
				</div>
				<div>
					<div>4</div>
					<div>
						<p>기타 계약</p>
						<small>부동산계약서, 가맹계약서, 공급계약서 등</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, defineEmits } from 'vue';

const emit = defineEmits(['title-update']);
const isMobile = ref(false);

function updateHomeTitle() {
	emit('title-update', '전자서명 시스템 DX SIGN');
}

const onResize = () => {
	isMobile.value = window.innerWidth <= 640;
};

onMounted(() => {
	onResize();
	updateHomeTitle();
	window.addEventListener('resize', onResize);
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style scoped>
.solution_sub_title {
	width: 1400px;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
}
.solution_sub_title_img img {
	width: 600px;
}

.solution_process {
	display: flex;
	margin: 0 auto;
	width: 100%;
	justify-content: center;
}

.solution_process img {
	margin: 0 20px;
}

.solution_process .solution_process_img {
	width: 100%;
	text-align: center;
	margin: 0 auto;
}
.solution_process_img img {
	width: 85px;
	height: 80px;
}

.process_arrow {
	width: 30px !important;
	height: 12px !important;
	text-align: center;
	position: relative;
	top: 50px;
}

.solution_process_desc {
	margin-top: 10px;
	font-size: 15px;
	width: 130px;
	margin-bottom: 10px;
	text-align: center;
	color: navy;
	font-weight: bold;
}

.solution_process_desc_resize {
	margin-top: 10px;
	font-size: 15px;
	width: 190px;
	margin-bottom: 10px;
	text-align: center;
	color: navy;
	font-weight: bold;
	margin-left: 30px;
}

.solution_content_background {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background: linear-gradient(
		to right,
		rgb(151, 191, 230),
		rgb(229, 237, 245) 20%,
		rgb(229, 237, 245) 80%,
		rgb(151, 191, 230)
	);
	border-radius: 100px;
	width: 840px;
	height: 160px;
	font-size: 20px;
	font-weight: bold;
	color: navy;
}

.solution_point_list {
	width: 100%;
	margin: 0 auto;
	height: 100%;
	margin-top: 40px;
}
.solution_point_list li {
	font-size: 20px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	position: relative;
	left: 20%;
}

.solution_point_list_text {
	margin: 10px;
}
.solution_table_content {
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.solution_content_list {
	width: 840px;
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 40px;
}
.solution_content_list > div {
	display: flex;
	align-items: start;
	gap: 20px;
}
.solution_content_list > div > div:nth-child(1) {
	display: flex;
	flex: none;
	justify-content: center;
	align-items: center;
	background: #004b96;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	border-radius: 8px;
	width: 32px;
	height: 32px;
}
.solution_content_list p {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 12px;
}
.solution_content_list small {
	font-size: 16px;
}

.sign_process_container {
	width: 840px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.sign_process_container > div:nth-child(1),
.sign_process_container > div:nth-child(4) {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	color: navy;
}
.sign_process_container > div:nth-child(2) {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}
.feature_box {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	background: linear-gradient(
		to right,
		rgb(151, 191, 230),
		rgb(229, 237, 245) 20%,
		rgb(229, 237, 245) 80%,
		rgb(151, 191, 230)
	);
	border-radius: 30px;
	padding: 2px 20px;
	margin: 10px 0;
	width: 260px;
}
.feature_box p {
	width: 180px;
	text-align: center;
	background: white;
	border-radius: 30px;
	padding: 6px 20px;
	font-size: 18px;
	font-weight: bold;
	color: navy;
}
.sign_process_container > div:nth-child(3) {
	margin-top: -40px;
}
.sign_process_container > div:nth-child(5) img {
	margin-top: -96px;
	width: 848px;
}
.sign_process_container > div:nth-child(6) {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: start;
	gap: 20px;
}
.process_box {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}
.process_box > div:nth-child(1) {
	position: absolute;
	top: -11px;
	left: -4px;
	font-size: 20px;
	font-weight: bold;
	color: rgb(151, 191, 230);
}
.process_box > div:nth-child(2) {
	font-size: 16px;
	font-weight: bold;
	padding: 10px 20px;
	border-radius: 20px;
	background: linear-gradient(
		to right,
		rgb(229, 237, 245) 20%,
		rgb(151, 191, 230)
	);
}
.sign_process_container p {
	text-align: center;
	font-size: 14px;
}

.sub42_con02 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 28px;
	width: 1400px;
	margin: 0 auto;
	padding-top: 60px;
}

.sub_title_badge_container {
	display: flex;
	justify-content: start;
	width: 1400px;
	margin: 0 auto;
	padding: 40px 0 0 0;
}

.sub_title_badge {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 12px 6px 12px;
	background: #004b96;
	color: #fff;
	font-size: 22px;
	font-weight: bold;
}

.features_container {
	width: 840px;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	justify-items: start;
	row-gap: 40px;
	font-size: 20px;
	font-weight: bold;
	color: navy;
}
.features_container > div {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.features_container img {
	width: 100px;
	height: 100px;
}
.features_container p {
	text-align: center;
}
.features_container > div > div:nth-child(2) {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 6px;
}

.hexagon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 160px;
	background: rgba(151, 191, 230, 0.7);
	clip-path: polygon(
		25% 6.7%,
		75% 6.7%,
		100% 50%,
		75% 93.3%,
		25% 93.3%,
		0% 50%
	);
}
.hexagon img {
	width: 68%;
	margin-right: 15px;
}

@media (max-width: 640px) {
	.sub42_text01 {
		font-size: 35px;
	}

	.sub42_list_title {
		width: 100%;
		padding: 30px 10px;
		font-size: 20px;
	}

	.sub_title_badge_container {
		width: 100%;
		padding: 32px 10px 0 10px;
	}

	.sub42_con02 {
		width: 100%;
		padding: 0 10px;
	}

	.solution_sub_title {
		width: 100%;
		font-weight: 700;
		font-size: 26px;
	}
	.solution_sub_title_img img {
		width: 400px;
	}
	.sub42_con02 {
		gap: 32px;
	}

	.solution_process {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.solution_process > div {
		display: flex;
		justify-content: center;
	}

	.solution_process_list {
		display: flex;
		align-items: center;
	}

	.process_arrow {
		position: static;
		margin: 0 8px !important;
	}

	.solution_content_background {
		width: 100%;
		text-align: center;
		line-height: 24px;
		font-size: 16px;
		padding: 12px 24px;
		height: auto;
	}

	.sub42_con02 {
		margin-top: 60px;
	}

	.sub42_con02_stitle {
		font-size: 18px;
	}

	.sub42_con03 {
		width: 100%;
		margin-top: 60px;
		padding-top: 60px;
		border-top: 1px solid #ccc;
		text-align: left;
	}

	.sub42_con03_text {
		font-size: 20px;
		line-height: 30px;
		text-align: left;
		margin-left: 10px;
	}

	.sub42_btn {
		width: 120px;
		margin: 0 auto;
		height: 35px;
		line-height: 12px;
		background: #004b96;
		color: #fff;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		margin-top: 20px;
	}

	.sub42_con02_list ul li {
		font-size: 15px;
	}

	.features_container {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 20px;
		font-size: 16px;
	}
	.features_container img {
		width: 80px;
		height: 80px;
	}

	.sign_process_container > div:nth-child(1) p {
		font-size: 20px;
	}
	.sign_process_container > div:nth-child(2) {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 0;
	}
	.sign_process_container > div:nth-child(3) {
		margin: -20px 0;
	}
	.sign_process_container > div:nth-child(4) p {
		font-size: 20px;
	}
	.sign_process_container > div:nth-child(5) {
		display: grid;
		gap: 20px 12px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.sign_process_container > div:nth-child(5) > div:nth-child(5) {
		grid-column: span 2 / span 2;
		display: flex;
		justify-content: center;
	}

	.feature_box {
		margin: 6px 0;
	}

	.solution_content_list {
		width: 100%;
		padding: 0 24px;
	}
	.solution_content_list p {
		font-size: 16px;
		margin-bottom: 4px;
	}
	.solution_content_list small {
		font-size: 14px;
	}

	.hexagon {
		margin-top: -24px;
		margin-bottom: 6px;
	}
}
</style>
