<template>
	<div>
		<div class="sub_title_badge_container">
			<div class="sub_title_badge">AI - OCR</div>
		</div>
		<div class="sub42_con02">
			<div v-if="isMobile" class="solution_sub_title">
				Deep Learning 알고리즘을<br />
				사용하여, 정확성과 기능이 향상된<br />
				광학 문자인식(OCR)솔루션<br />
			</div>
			<div v-else class="solution_sub_title">
				Deep Learning 알고리즘을 사용하여,<br />
				정확성과 기능이 향상된 광학 문자인식(OCR)솔루션
			</div>
			<ul v-if="isMobile" class="solution_process">
				<div>
					<li class="solution_process_list">
						<div>
							<div class="solution_process_desc">데이터 자동영역 인식</div>
							<div class="solution_process_img">
								<img :src="require('@/assets/image/ocr/process1.png')" />
							</div>
							<div class="solution_process_desc">
								카메라를 활용하여<br />
								데이터 추출영역 <br />
								자동 인식
							</div>
						</div>
						<div>
							<img
								class="process_arrow"
								:src="require('@/assets/image/solution_ai_content5.png')"
							/>
						</div>
					</li>
					<li class="solution_process_list">
						<div>
							<div class="solution_process_desc">이미지 전처리</div>
							<div class="solution_process_img">
								<img :src="require('@/assets/image/ocr/process2.png')" />
							</div>
							<div class="solution_process_desc">
								이미지 인식률 <br />
								향상을 위한<br />
								이미지 이진법화
							</div>
						</div>
						<div>
							<div>
								<img
									class="process_arrow"
									:src="require('@/assets/image/solution_ai_content5.png')"
								/>
							</div>
						</div>
					</li>
				</div>
				<div>
					<li class="solution_process_list move_right">
						<div>
							<div class="solution_process_desc_resize">
								인공지능을 활용한 데이터 판별
							</div>
							<div class="solution_process_img" style="display: flex">
								<img :src="require('@/assets/image/ocr/process3.png')" />
								<img :src="require('@/assets/image/ocr/process4.png')" />
							</div>
							<div class="solution_process_desc_resize">
								인공지능을 통해 학습된<br />
								데이터를 신경망을 통해<br />
								비교검증하여 데이터 판별
							</div>
						</div>
						<div>
							<img
								class="process_arrow"
								:src="require('@/assets/image/solution_ai_content5.png')"
							/>
						</div>
					</li>
					<li class="solution_process_list">
						<div>
							<div class="solution_process_desc">데이터 추출</div>
							<div class="solution_process_img">
								<img :src="require('@/assets/image/ocr/process5.png')" />
							</div>
							<div class="solution_process_desc">
								이미지에서<br />
								결과 판별하여<br />
								데이터화
							</div>
						</div>
					</li>
				</div>
			</ul>
			<ul v-else class="solution_process">
				<li class="solution_process_list">
					<div class="solution_process_desc">데이터 자동영역 인식</div>
					<div class="solution_process_img">
						<img :src="require('@/assets/image/ocr/process1.png')" />
						<img
							class="process_arrow"
							:src="require('@/assets/image/solution_ai_content5.png')"
						/>
					</div>
					<div class="solution_process_desc">
						카메라를 활용하여<br />
						데이터 추출영역 <br />자동 인식
					</div>
				</li>
				<li class="solution_process_list">
					<div class="solution_process_desc">이미지 전처리</div>
					<div class="solution_process_img">
						<img :src="require('@/assets/image/ocr/process2.png')" />
						<img
							class="process_arrow"
							:src="require('@/assets/image/solution_ai_content5.png')"
						/>
					</div>
					<div class="solution_process_desc">
						이미지 인식률 <br />향상을 위한<br />
						이미지 이진법화
					</div>
				</li>
				<li class="solution_process_list">
					<div class="solution_process_desc_resize">
						인공지능을 활용한 데이터 판별
					</div>
					<div class="solution_process_img">
						<img :src="require('@/assets/image/ocr/process3.png')" />
						<img :src="require('@/assets/image/ocr/process4.png')" />
						<img
							class="process_arrow"
							:src="require('@/assets/image/solution_ai_content5.png')"
						/>
					</div>
					<div class="solution_process_desc_resize">
						인공지능을 통해 학습된<br />
						데이터를 신경망을 통해<br />
						비교검증하여 데이터 판별
					</div>
				</li>
				<li class="solution_process_list move_right">
					<div class="solution_process_desc">데이터 추출</div>
					<div class="solution_process_img">
						<img :src="require('@/assets/image/ocr/process5.png')" />
					</div>
					<div class="solution_process_desc">
						이미지에서<br />
						결과 판별하여<br />
						데이터화
					</div>
				</li>
			</ul>
			<div class="solution_content_background">
				<p>
					AI OCR은 기존 OCR 기술에 비해 더 높은 정확도와 다양한 적용범위를
					가지고 있습니다.
				</p>
				<p>
					딥러닝 기술의 발전으로 AI OCR은 지속적으로 성능이 향상되고 있으며,
				</p>
				<p>다양한 산업분야에서 적용하고 있습니다.</p>
			</div>
			<div>
				<ol class="ordered_list">
					<li>
						<div>1</div>
						<div>
							<p>
								스마트폰이나 태블릿 등 카메라로 데이터를 수집 후 DX - AI OCR 로
								인식하여 데이터를 활용합니다.
							</p>
						</div>
					</li>
					<li>
						<div>2</div>
						<div>
							<p>
								다양한 산업과 업무에서 데이터를 빠르고 간편하게 수집 / 입력하고
								모니터링할 수 있습니다.
							</p>
						</div>
					</li>
					<li>
						<div>3</div>
						<div>
							<p>
								Deep Learning을 활용하여, 기존 OCR의 적용이 어려운 환경에
								유연하게 대응할 수 있습니다.
							</p>
						</div>
					</li>
				</ol>
			</div>
		</div>
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> OCR VS
				DX – AI OCR 비교
			</div>
			<table class="table_container">
				<thead>
					<tr>
						<th scope="col"></th>
						<th class="table_header" scope="col"><p>OCR</p></th>
						<th class="table_header" scope="col"><p>DX – AI OCR</p></th>
					</tr>
				</thead>
				<tbody>
					<tr class="table_content">
						<th scope="row">기술</th>
						<td>
							<p>규칙 기반의 알고리즘을</p>
							<p>사용하여 문자 인식</p>
						</td>
						<td>
							<p>딥러닝 AI 알고리즘을 사용해</p>
							<p>기존 OCR의 정확성과 범용성을 향상시킴</p>
						</td>
					</tr>
					<tr class="table_content">
						<th scope="row">정확도</th>
						<td>
							<p>촬영 문서의 품질에 따라 달라짐</p>
							<small>
								- 글꼴, 크기, 배경, 노이즈, 왜곡 등의 변화에 취약, 정확도가
								상대적으로 낮음
							</small>
						</td>
						<td>
							<p>다양한 환경에서 높은 정확도를 보임</p>
							<p>학습을 통해 정확도를 지속적으로 향상시킬 수 있음</p>
						</td>
					</tr>
					<tr class="table_content">
						<th v-if="isMobile" scope="row">
							사용<br />
							범위
						</th>
						<th v-else scope="row">사용 범위</th>
						<td>
							<p>주로 인쇄물 디지털화에 사용</p>
						</td>
						<td>
							<p>제조/행정 등 입력 업무 혁신에 적합</p>
						</td>
					</tr>
					<tr class="table_content">
						<th v-if="isMobile" scope="row">
							적용<br />
							분야
						</th>
						<th v-else scope="row">적용 분야</th>
						<td>
							<p>제한된 환경에서 촬영된</p>
							<p>고품질 이미지 텍스트의 디지털화</p>
						</td>
						<td>
							<p>물류 및 제조 입력 업무 혁신,</p>
							<p>금융&인사 등 행정 서류 인식, 자동차 번호판 인식 등</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 적용
				분야
			</div>
			<div class="solution_content_list">
				<ul>
					<li>- 전자저울, 계측기 등 7 – Segments 인식</li>
					<li>- 신분증, 여권, 통장사본 등 서류 인식</li>
					<li>- 자동차 번호판, 도로 및 산업현장 표지판 인식</li>
					<li>- 촬영을 통해 자동화/단순화 가능한 업무</li>
				</ul>
				<img
					id="solution_ai_usecase_image"
					:src="require('@/assets/image/ocr/applying_field.png')"
				/>
			</div>
		</div>
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" />
				제공 서비스
			</div>
			<div class="solution_content_list">
				<ul>
					<li>- 고객 니즈를 반영한 API 제공</li>
					<li>- 기존환경 / 시스템에 활용 가능하도록 맞춤 개발 지원</li>
					<li>- 데이터 추출 후 모니터링 및 리포트 시스템 제공</li>
				</ul>
				<img :src="require('@/assets/image/ocr/offering_service.png')" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, defineEmits } from 'vue';

const emit = defineEmits(['title-update']);
const isMobile = ref(false);

function updateHomeTitle() {
	emit('title-update', 'DX - AI OCR 솔루션');
}

const onResize = () => {
	isMobile.value = window.innerWidth <= 640;
};

onMounted(() => {
	onResize();
	updateHomeTitle();
	window.addEventListener('resize', onResize);
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style scoped>
.solution_sub_title {
	width: 1400px;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
}

.solution_process {
	display: flex;
	margin: 0 auto;
	width: 100%;
	justify-content: center;
}

.solution_process img {
	margin: 0 20px;
}

.solution_process .solution_process_img {
	width: 100%;
	text-align: center;
	margin: 0 auto;
}
.solution_process_img img {
	width: 85px;
	height: 80px;
}

.process_arrow {
	width: 30px !important;
	height: 12px !important;
	text-align: center;
	position: relative;
	top: 50px;
}

.solution_process_desc {
	margin-top: 10px;
	font-size: 15px;
	width: 130px;
	margin-bottom: 10px;
	text-align: center;
	color: navy;
	font-weight: bold;
}

.solution_process_desc_resize {
	margin-top: 10px;
	font-size: 15px;
	width: 190px;
	margin-bottom: 10px;
	text-align: center;
	color: navy;
	font-weight: bold;
	margin-left: 30px;
}

.solution_content_background {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background: linear-gradient(
		to right,
		rgb(151, 191, 230),
		rgb(229, 237, 245) 20%,
		rgb(229, 237, 245) 80%,
		rgb(151, 191, 230)
	);
	border-radius: 100px;
	width: 840px;
	height: 160px;
	font-size: 20px;
	font-weight: bold;
	color: navy;
}

.solution_point_list {
	width: 100%;
	margin: 0 auto;
	height: 100%;
	margin-top: 40px;
}
.solution_point_list li {
	font-size: 20px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	position: relative;
	left: 20%;
}

.solution_point_list_text {
	margin: 10px;
}
.solution_table_content {
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.solution_content_list {
	width: 840px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.solution_content_list ul {
	font-size: 20px;
	line-height: 44px;
}

.solution_content_list img {
	height: 220px;
}

.sub42_con02 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 68px;
	width: 1400px;
	margin: 0 auto;
	padding-top: 60px;
}

.sub_title_badge_container {
	display: flex;
	justify-content: start;
	width: 1400px;
	margin: 0 auto;
	padding: 40px 0 0 0;
}

.sub_title_badge {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 12px 6px 12px;
	background: #004b96;
	color: #fff;
	font-size: 22px;
	font-weight: bold;
}

.ordered_list {
	display: flex;
	gap: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 840px;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.ordered_list li {
	display: flex;
	align-items: center;
	gap: 16px;
}

.ordered_list > li > :nth-child(1) {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #004b96;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	border-radius: 8px;
	width: 32px;
	height: 32px;
}

.ordered_list > li > :nth-child(2) {
	font-size: 20px;
	font-weight: bold;
}

.table_container {
	width: 840px;
	border-collapse: collapse;
}

.table_container > thead > tr > th:nth-child(1) {
	width: 100px;
}

.table_container > thead > tr > th:nth-child(2) {
	width: 320px;
}

.table_container > thead > tr > th:nth-child(3) {
	width: 420px;
}

.table_header > p {
	display: inline-block;
	background: linear-gradient(
		to right,
		rgb(151, 191, 230),
		rgb(229, 237, 245) 20%,
		rgb(229, 237, 245) 80%,
		rgb(151, 191, 230)
	);
	border-radius: 24px;
	font-size: 20px;
	font-weight: bold;
	color: navy;
	margin: 0 0 20px 0;
	padding: 12px 80px;
}

.table_content {
	color: navy;
	font-size: 18px;
	height: 68px;
	background: linear-gradient(
		to right,
		rgb(151, 191, 230),
		rgb(229, 237, 245) 20%,
		rgb(229, 237, 245) 80%,
		rgb(151, 191, 230)
	);
}

.table_content > th {
	border-top: #ccdcea 2px solid;
	margin-left: 10px;
}

.table_content > td {
	font-weight: bold;
	border-top: #ccdcea 2px solid;
	padding: 0 20px;
}

.table_content small {
	color: rgba(0, 0, 128, 0.5);
	font-size: 14px;
}

@media (max-width: 640px) {
	.sub42_text01 {
		font-size: 35px;
	}

	.sub42_list_title {
		width: 100%;
		padding: 30px 10px;
		font-size: 20px;
	}

	.sub_title_badge_container {
		width: 100%;
		padding: 32px 10px 0 10px;
	}

	.sub42_con02 {
		width: 100%;
		padding: 0 10px;
	}

	.solution_sub_title {
		width: 100%;
		font-weight: 700;
		font-size: 26px;
	}

	.sub42_con02 {
		gap: 32px;
	}

	.solution_process {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.solution_process > div {
		display: flex;
		justify-content: center;
	}

	.solution_process_list {
		display: flex;
		align-items: center;
	}

	.process_arrow {
		position: static;
		margin: 0 8px !important;
	}

	.solution_content_background {
		width: 100%;
		text-align: center;
		line-height: 24px;
		font-size: 16px;
		padding: 20px 52px;
		height: auto;
	}

	.ordered_list {
		width: 100%;
	}
	.ordered_list p {
		font-size: 16px;
	}
	.ordered_list > li {
		gap: 12px;
	}
	.ordered_list > li > :nth-child(1) {
		flex: none;
	}

	.sub42_con02 {
		margin-top: 60px;
	}

	.sub42_con02_stitle {
		font-size: 18px;
	}

	.table_container {
		width: 100%;
	}
	.table_container > thead p {
		font-size: 16px;
		padding: 8px 24px;
	}
	.table_container > tbody p,
	.table_container > tbody th {
		font-size: 14px;
	}

	.table_content > td {
		padding: 0 12px;
	}
	.table_content small {
		font-size: 12px;
	}

	.solution_content_list {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.solution_content_list ul {
		font-size: 16px;
		line-height: 32px;
	}

	.sub42_con03 {
		width: 100%;
		margin-top: 60px;
		padding-top: 60px;
		border-top: 1px solid #ccc;
		text-align: left;
	}

	.sub42_con03_text {
		font-size: 20px;
		line-height: 30px;
		text-align: left;
		margin-left: 10px;
	}

	.sub42_btn {
		width: 120px;
		margin: 0 auto;
		height: 35px;
		line-height: 12px;
		background: #004b96;
		color: #fff;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		margin-top: 20px;
	}

	.sub42_con02_list ul li {
		font-size: 15px;
	}
}
</style>
