<template>
	<div>
		<!-- 도입사례 뷰페이지 두번째 상단 영역 시작 -->

		<div class="sub42_con01">
			<div class="sub42_stitle_btn">MES</div>
			<div class="sub42_stitle">생산 관리 시스템 구축</div>
			<div class="sub42_img01">
				<img :src="require('@/assets/image/mesDetailImage1.png')" />
				<img :src="require('@/assets/image/mesDetailImage2.png')" />
			</div>
		</div>
		<!-- 도입사례 뷰페이지 두번째 상단 영역 끝 -->

		<!-- 도입사례 뷰페이지 세번째 상단 영역 시작 -->
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 구축
				정보
			</div>
			<div class="sub42_con02_list">
				<ul>
					<li>· 공정 / 부서 / 설비 별 점검 현황</li>
					<li>· 점검 결재 현황 - 활성 / 비활성 시트 관리</li>
					<li>· BATCH HISTORY 관리</li>
					<li>· 사전 비가동 예약기능</li>
					<li>· 이메일 발신 기능</li>
				</ul>
			</div>
		</div>
		<!-- 도입사례 뷰페이지 세번째 상단 영역 끝 -->

		<!-- 도입사례 뷰페이지 네번째 상단 영역 시작 -->
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 진행
				과정
				<div class="sub42_text02">
					컨설팅 > 기획 > 디자인 > 개발
					<br v-if="isMiniMobile" />
					> 현지 셋업 ( 말레이시아, 천진, 서안, 헝가리, 울산)
				</div>
			</div>
			<div class="sub42_con02_img">
				<img
					v-if="!isMiniMobile"
					:src="require('@/assets/image/sub42-2.png')"
				/>
				<div class="sub42_con02_img_col1" v-if="isMiniMobile">
					<img
						:src="require('@/assets/image/usecase_detail_process_icon01.png')"
					/>
					<img
						:src="require('@/assets/image/usecase_detail_process_icon02.png')"
					/>
					<img
						:src="require('@/assets/image/usecase_detail_process_icon03.png')"
					/>
				</div>
				<div class="sub42_con02_img_col2" v-if="isMiniMobile">
					<img
						:src="require('@/assets/image/usecase_detail_process_icon04.png')"
					/>
					<img
						:src="require('@/assets/image/usecase_detail_process_icon05.png')"
					/>
				</div>
			</div>
		</div>
		<!-- 도입사례 뷰페이지 네번째 상단 영역 끝 -->

		<!-- 도입사례 뷰페이지 여섯번째 상단 영역 시작 -->
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 구축
				결과
			</div>
			<div class="sub42_con02_list">
				<ul>
					<li>
						· 공정 / 부서 / 설비 별 점검 현황을 시스템에서 관리 및 기록,
						<br v-if="isMiniMobile" />점검 가능
					</li>
					<li>· 현황을 그래프로 표현하여 즉각적인 시각적 인식 가능</li>
					<li>
						· 점검항목 결재기능 및 긴급상황 발생 시 E-mail
						<br v-if="isMiniMobile" />발송 기능을 두어 점검항목 작성여부 및
						내용을 관리자가 <br v-if="isMiniMobile" />시스템으로 확인할 수
						있으며 긴급 상황도 이메일 확인 가능
					</li>
				</ul>
			</div>
		</div>
		<!-- 도입사례 뷰페이지 여섯번째 상단 영역 끝 -->
	</div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, defineEmits } from 'vue';

const emit = defineEmits(['title-update']);
const isMiniMobile = ref(window.innerWidth <= 435);

function updateHomeTitle() {
	emit('title-update', '생산 관리 시스템 구축 사례');
}

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	window.addEventListener('resize', onResize);

	onResize();
	updateHomeTitle();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style>
.sub42_con01 {
	width: 1400px;
	margin: 0 auto;
	padding-top: 40px;
}

.sub42_stitle_btn {
	width: 100px;
	height: 36px;
	background: #004b96;
	color: #fff;
	text-align: center;
	font-size: 22px;
	font-weight: bold;
	padding-top: 4px;
}

.sub42_stitle {
	font-size: 40px;
	font-weight: bold;
	padding-top: 20px;
}

.sub42_img01 {
	width: 1400px;
	margin: 0 auto;
	padding-top: 40px;
	display: flex;
	gap: 20px;
}

.sub42_con02 {
	width: 1400px;
	margin: 0 auto;
	padding-top: 60px;
}

.sub42_con02_stitle {
	width: 1400px;
	margin: 0 auto;
	font-size: 30px;
	font-weight: bold;
}

.sub42_con02_list {
	font-size: 20px;
	line-height: 32px;
	padding-top: 20px;
}

.sub42_con02_img {
	padding-top: 40px;
}
</style>
