<template>
	<div class="sub31_con_wrapper">
		<div
			class="sub31_con_title"
			data-aos="fade-down"
			data-aos-duration="800"
			data-aos-easing="linear"
		>
			<p class="sub31_text01">구축 프로세스</p>
			<p class="sub31_text02">
				체계화된 구축 진행 프로세스를 활용<span class="br_mobile"></span> 최적의
				시스템 구현을 제공합니다.
			</p>
		</div>

		<div id="sub31_con01_warp">
			<div
				class="sub31_con01"
				data-aos="fade-up"
				data-aos-duration="800"
				data-aos-easing="ease-in-sine"
			>
				<div class="sub31_con01_left">
					<img :src="require('@/assets/image/sub31-1.jpg')" />
				</div>
				<div class="sub31_con01_center">
					<div class="sub31_dot">
						<img :src="require('@/assets/image/sub31_dot.png')" />
					</div>
				</div>
				<div class="sub31_con01_right">
					<div class="sub31_text_warp">
						<p class="sub31_text03">01</p>
						<p class="sub31_text04">공장 생산 프로세스 분석</p>
						<p class="sub31_text05">
							공장 환경마다 다른 최적화된 프로세스 분석
							<span class="br_pc"></span>
							및 구축 범위 결정
						</p>
					</div>
				</div>
			</div>

			<div
				class="sub31_con01 sub31_con01_reverse"
				data-aos="fade-up"
				data-aos-duration="800"
				data-aos-easing="ease-in-sine"
			>
				<div class="sub31_con01_left">
					<div class="sub31_text_warp">
						<p class="sub31_text03">02</p>
						<p class="sub31_text04">기업 환경에 최적화된 컨설팅</p>
						<p class="sub31_text05">
							공장 NEED에 부합하는<span class="br_pc"></span>
							맞춤형 컨설팅 제공
						</p>
					</div>
				</div>
				<div class="sub31_con01_center">
					<div class="sub31_dot">
						<img :src="require('@/assets/image/sub31_dot.png')" />
					</div>
				</div>
				<div class="sub31_con01_right">
					<img :src="require('@/assets/image/sub31-2.jpg')" />
				</div>
			</div>

			<div
				class="sub31_con01"
				data-aos="fade-up"
				data-aos-duration="800"
				data-aos-easing="ease-in-sine"
			>
				<div class="sub31_con01_left">
					<img :src="require('@/assets/image/sub31-3.jpg')" />
				</div>
				<div class="sub31_con01_center">
					<div class="sub31_dot">
						<img :src="require('@/assets/image/sub31_dot.png')" />
					</div>
				</div>
				<div class="sub31_con01_right">
					<div class="sub31_text_warp">
						<p class="sub31_text03">03</p>
						<p class="sub31_text04">FLOW 파악 및 설계 구현</p>
						<p class="sub31_text05">
							프로세스에 최적화된<span class="br_pc"></span>
							Flow파악 및 설계 구현
						</p>
					</div>
				</div>
			</div>

			<div
				class="sub31_con01 sub31_con01_reverse"
				data-aos="fade-up"
				data-aos-duration="800"
				data-aos-easing="ease-in-sine"
			>
				<div class="sub31_con01_left">
					<div class="sub31_text_warp">
						<p class="sub31_text03">04</p>
						<p class="sub31_text04">서버 및 개발환경 셋업</p>
						<p class="sub31_text05">
							각 공장 기반환경에 맞는 서버 및<span class="br_pc"></span>
							개발환경 셋업
						</p>
					</div>
				</div>
				<div class="sub31_con01_center">
					<div class="sub31_dot">
						<img :src="require('@/assets/image/sub31_dot.png')" />
					</div>
				</div>
				<div class="sub31_con01_right">
					<img :src="require('@/assets/image/sub31-4.jpg')" />
				</div>
			</div>

			<div
				class="sub31_con01"
				data-aos="fade-up"
				data-aos-duration="800"
				data-aos-easing="ease-in-sine"
			>
				<div class="sub31_con01_left">
					<img :src="require('@/assets/image/sub31-5.jpg')" />
				</div>
				<div class="sub31_con01_center">
					<div class="sub31_dot">
						<img :src="require('@/assets/image/sub31_dot.png')" />
					</div>
				</div>
				<div class="sub31_con01_right">
					<div class="sub31_text_warp">
						<p class="sub31_text03">05</p>
						<p class="sub31_text04">프로그램 개발</p>
						<p class="sub31_text05">
							체계화된 프로그램 개발 진행으로<span class="br_pc"></span>
							최적의 시스템 구현
						</p>
					</div>
				</div>
			</div>

			<div
				class="sub31_con01 sub31_con01_reverse"
				data-aos="fade-up"
				data-aos-duration="800"
				data-aos-easing="ease-in-sine"
			>
				<div class="sub31_con01_left">
					<div class="sub31_text_warp">
						<p class="sub31_text03">06</p>
						<p class="sub31_text04">내부테스트 및 검수</p>
						<p class="sub31_text05">
							개발 후 여러 차례의 검증 테스트를 거쳐<span class="br_pc"></span>
							안정화된 시스템 제공
						</p>
					</div>
				</div>
				<div class="sub31_con01_center">
					<div class="sub31_dot">
						<img :src="require('@/assets/image/sub31_dot.png')" />
					</div>
				</div>
				<div class="sub31_con01_right">
					<img :src="require('@/assets/image/sub31-6.jpg')" />
				</div>
			</div>

			<div
				class="sub31_con01"
				data-aos="fade-up"
				data-aos-duration="800"
				data-aos-easing="ease-in-sine"
			>
				<div class="sub31_con01_left">
					<img :src="require('@/assets/image/sub31-7.jpg')" />
				</div>
				<div class="sub31_con01_center">
					<div class="sub31_dot">
						<img :src="require('@/assets/image/sub31_dot.png')" />
					</div>
				</div>
				<div
					class="sub31_con01_right"
					data-aos="fade-up"
					data-aos-duration="1000"
				>
					<div class="sub31_text_warp">
						<p class="sub31_text03">07</p>
						<p class="sub31_text04">서비스 안정화 및 운영</p>
						<p class="sub31_text05">
							실 서버에 서비스 배포 후 실시간 모니터링 및<span
								class="br_pc"
							></span>
							즉각적인 오류 대응, 운영 기능 개선
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const isViewProcess = ref(false);

onMounted(() => {
	document.addEventListener('DOMContentLoaded', function () {
		const element = document.querySelector('.sub31_con01_left');

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					isViewProcess.value = true;
				} else {
					isViewProcess.value = false;
				}
			});
		});

		observer.observe(element);
	});
});
</script>

<style>
.sub31_con_title {
	width: 100%;
	text-align: center;
}

.sub31_text01 {
	margin-top: 180px;
	font-size: 60px;
	letter-spacing: -1px;
	font-weight: bold;
}

.sub31_text02 {
	font-size: 22px;
	line-height: 30px;
	padding-top: 20px;
}

.sub31_text03 {
	font-size: 50px;
	font-weight: bold;
	padding-top: 20px;
}

.sub31_text04 {
	font-size: 36px;
	font-weight: bold;
	padding-top: 20px;
}

.sub31_text05 {
	font-size: 24px;
	line-height: 32px;
	padding-top: 20px;
}

#sub31_con01_warp {
	width: 1300px;
	height: auto;
	margin: 0 auto;
	background: url('/src/assets/image/sub31_line.png') center top no-repeat;
	margin-top: 100px;
}

.sub31_con01 {
	width: 100%;
	height: 450px;
	display: flex;
}

.sub31_con01_left {
	width: 650px;
}

.sub31_con01_center {
	position: relative;
	width: 100px;
	text-align: center;
}

.sub31_con01_right {
	width: 650px;
	padding-left: 50px;
}

.sub31_text_warp {
	padding-left: 50px;
	padding-top: 50px;
}

.sub31_con01_reverse .sub31_dot {
	position: absolute;
	top: 84px;
	right: -30px;
}

.sub31_dot {
	position: absolute;
	left: 35%;
	top: 84px;
}

.fot_ban_warp {
	width: 1400px;
	height: 360px;
	margin: 0 auto;
	padding-top: 60px;
}

.fot_ban01 {
	width: 690px;
	float: left;
}

.fot_ban02 {
	width: 690px;
	float: right;
}
@media screen and (max-width: 435px) {
	.sub31_text01 {
		font-size: 30px;
	}

	.sub31_text02 {
		font-size: 20px;
	}

	.sub31_text03 {
		font-size: 20px;
		font-weight: bold;
		padding-top: 0px;
	}

	.sub31_text04 {
		font-size: 25px;
		font-weight: bold;
		padding-top: 20px;
	}

	.sub31_text05 {
		font-size: 20px;
		line-height: 32px;
		padding-top: 20px;
	}

	.sub31_con01 {
		display: flex;
		flex-direction: column;
		height: max-content;
	}

	.sub31_con01_reverse {
		flex-direction: column-reverse;
	}

	.sub31_con01_right img,
	.sub31_con01_left img {
		width: 100vw;
	}

	.sub31_con01_right {
		width: 100vw;
		padding: 0;
	}

	.sub31_dot {
		display: none;
	}

	.sub31_text_warp {
		padding-top: 10px;
		padding-left: 20px;
		padding-bottom: 20px;
	}
	.sub31_con_wrapper {
		padding-bottom: 20px;
		position: relative;
		display: block;
	}
}
/* 아이폰 12 pro에서 발생하는 문제  */
@media screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
	.sub31_text05 {
		font-size: 20px;
	}
}
</style>
