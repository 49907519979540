<template>
	<div class="about_more_baaner_warp">
		<div class="fot_ban_warp">
			<div class="fot_ban01">
				<RouterLink to="/smartFactory"
					><img :src="require('@/assets/image/fot_ban01.png')"
				/></RouterLink>
			</div>
			<div class="fot_ban02">
				<RouterLink to="/contact"
					><img :src="require('@/assets/image/fot_ban02.png')"
				/></RouterLink>
			</div>
		</div>
	</div>
</template>

<script setup></script>

<style>
.fot_ban_warp {
	max-width: 1400px;
	height: 360px;
	margin: 0 auto;
	padding-top: 60px;
}

.fot_ban01 {
	width: 690px;
	float: left;
}

.fot_ban02 {
	width: 690px;
	float: right;
}

@media screen and (max-width: 435px) {
	.about_more_baaner_warp {
		max-height: 400px;
	}
	.fot_ban_warp {
		max-width: 100%;
		width: 100%;
		height: 400px !important;
		min-height: 500px;
		margin: 0 auto;
		padding-top: 0 !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		position: relative !important;
		gap: 10px;
	}

	.fot_ban01,
	.fot_ban02 {
		width: 100% !important;
		margin: 0 auto;
	}

	.fot_ban01 img {
		width: 350px !important;
	}

	.fot_ban02 img {
		width: 350px !important;
	}
}
</style>
