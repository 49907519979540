<template>
	<div id="main_con04_warp">
		<div class="main_con04_title">
			<p class="main_con04_text01">OUR PARTNER</p>
			<p class="main_con04_text02">우리의 고객사</p>
			<p class="main_con04_text03">
				DEJAY DX는 스마트 팩토리 <br v-if="isMiniMobile" />전문 기업으로
				고객사와 미래를 함께 만들어 나갑니다.
			</p>
		</div>
		<div class="main_con04">
			<div class="logosWrapper">
				<ul class="main_con04_logos" id="roller1">
					<RouterLink to="/clients">
						<li><img :src="require('@/assets/image/main_con04-1.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-2.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-3.png')" /></li>
					</RouterLink>
					<RouterLink to="/clients">
						<li><img :src="require('@/assets/image/main_con04-4.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-5.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-6.png')" /></li>
					</RouterLink>
					<RouterLink to="/clients">
						<li><img :src="require('@/assets/image/main_con04-7.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-8.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-9.png')" /></li>
					</RouterLink>
					<RouterLink to="/clients">
						<li>
							<img :src="require('@/assets/image/main_con04-10.png')" />
						</li>
						<li>
							<img :src="require('@/assets/image/main_con04-11.png')" />
						</li>
						<li>
							<img :src="require('@/assets/image/main_con04-12.png')" />
						</li>
					</RouterLink>
					<RouterLink to="/clients">
						<li>
							<img :src="require('@/assets/image/main_con04-13.png')" />
						</li>
						<li>
							<img :src="require('@/assets/image/main_con04-14.png')" />
						</li>
						<li>
							<img :src="require('@/assets/image/main_con04-15.png')" />
						</li>
					</RouterLink>
				</ul>
			</div>
		</div>
		<!-- <div class="main_con04_dot">
			<ul>
				<li><img :src="require('@/assets/image/main_con04_slide.png')" /></li>
				<li><img :src="require('@/assets/image/main_con04_slide2.png')" /></li>
				<li><img :src="require('@/assets/image/main_con04_slide2.png')" /></li>
			</ul>
		</div> -->
	</div>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from 'vue';

const isMiniMobile = ref(window.innerWidth <= 435);

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	const list = document.querySelector('.main_con04_logos');
	const clone = list.cloneNode(true);
	clone.id = 'roller2';
	document.querySelector('.logosWrapper').appendChild(clone);

	const roller1 = document.querySelector('#roller1');
	const roller2 = document.querySelector('#roller2');

	roller1.style.left = '0px';
	roller2.style.left = `100%`;

	window.addEventListener('resize', onResize);
	onResize();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style>
#main_con04_warp {
	width: 100%;
	height: 600px;
	background: url('/src/assets/image/main_con04_bg.png') center top;
	background-size: cover;
}

.main_con04 {
	width: 100%;
	margin: 0 auto;
	padding-top: 60px;
	overflow: hidden;
}

.main_con04_logos {
	width: 200%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.logosWrapper {
	display: flex;
	overflow: hidden;
	width: 200%;
}

#roller1 {
	display: flex;
	animation: rollingleft 25s linear infinite;
}

#roller2 {
	display: flex;
	animation: rollingleft 25s linear infinite;
}

#roller1 a,
#roller2 a {
	display: flex;
	flex-direction: row;
}

@keyframes rollingleft {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

.main_con04 ul li {
	width: 265px;
	margin: 3px 3px;
}

.main_con04_logos img {
	width: 265px;
	height: auto;
}

.main_con04_dot {
	clear: both;
	width: 400px;
	margin: 0 auto;
	padding-top: 30px;
}

.main_con04_dot ul li {
	float: left;
	margin: 0 9px;
}

.main_con04_title {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	padding-top: 100px;
}

.main_con04_text01 {
	font-size: 20px;
	font-weight: bold;
}

.main_con04_text02 {
	font-size: 50px;
	font-weight: bold;
	line-height: 62px;
	padding-top: 30px;
}

.main_con04_text03 {
	font-size: 22px;
	line-height: 34px;
	color: #7d7d7d;
	padding-top: 30px;
}

@media screen and (max-width: 435px) {
	.main_con04_text01 {
		font-size: 1rem;
	}
	.main_con04_text02 {
		font-size: 1.2rem;
		padding-top: 0 !important;
	}

	.main_con04_text03 {
		padding: 0 10px;
		width: 100%;
		text-align: center;
		font-size: 12px;
		line-height: 18px;
	}
	.main_con04 ul li {
		width: 150px;
		margin: 3px 3px;
	}

	.main_con04_logos img {
		width: 150px;
		height: auto;
	}

	#roller1 a,
	#roller2 a {
		display: flex;
		flex-direction: row !important;
		gap: 3px;
	}
	#main_con04_warp {
		width: 100%;
		height: 700px;
		background: url('/src/assets/image/main_con04_bg.png') center top;
		background-size: cover;
	}

	.main_con04 {
		padding-top: 30px;
	}

	#roller1 {
		flex-direction: column;
	}

	#roller2 {
		flex-direction: column;
	}

	#roller1 ul,
	#roller2 ul {
		display: flex;
		flex-direction: column;
	}
}
</style>
