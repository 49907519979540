<template>
	<div id="main_con01_warp" ref="panel">
		<div class="main_con01_title" ref="mainTitle">
			<p v-if="!isMiniMobile" class="service_process_text">SERVICE PROCESS</p>
			<p v-if="isMiniMobile" class="service_process_text_mobile">
				SERVICE PROCESS
			</p>
			<p v-if="!isMiniMobile" class="service_process_title">
				<span style="font-weight: bold">스마트팩토리 도입</span>은 <br />
				올바른 프로세스 분석 설계가 가장 중요합니다.
			</p>
			<p class="service_process_title_mobile" v-if="isMiniMobile">
				<span style="font-weight: bold">스마트팩토리 도입</span>은 <br />
				올바른 프로세스 분석 설계가 가장 중요합니다.
			</p>
			<p class="main_con01_text03" v-if="!isMiniMobile">
				체계화된 구축 진행 프로세스를 활용하여 해당 공장의 공정에 필요한 기능 및
				구현 범위를<span class="br_pc"></span>
				컨설팅을 통해 확정한 후, 이에 맞는 최적의 시스템 구현을 제공합니다.
			</p>

			<p class="main_con01_text03" v-if="isMiniMobile">
				<span>체계화된 구축 진행 프로세스를 활용하여</span
				><span>
					해당 공장의 공정에 필요한 기능 및 구현 범위를 컨설팅을 통해
				</span>
				<span> 확정한 후, 이에 맞는 최적의 시스템 구현을 제공합니다.</span>
			</p>
		</div>

		<div class="main_con01_warp_contents">
			<div class="main_con_icons">
				<RouterLink to="/smartFactory" class="icons-col">
					<img
						:src="require('@/assets/image/service_process_icon01.png')"
						class="main_con_icon_image"
						alt="service_process_icon"
					/>
					<img
						:src="require('@/assets/image/service_process_icon02.png')"
						class="main_con_icon_image"
						alt="service_process_icon"
					/>
				</RouterLink>
				<RouterLink to="/smartFactory" class="icons-col">
					<img
						:src="require('@/assets/image/service_process_icon03.png')"
						alt="main_con_icon_image"
						class="main_con_icon_image"
					/>
					<img
						:src="require('@/assets/image/service_process_icon04.png')"
						alt="main_con_icon_image"
						class="main_con_icon_image"
					/>
				</RouterLink>
			</div>
			<div class="background-circle" data-aos="zoom-in"></div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const panel = ref(null);
const mainTitle = ref(null);

const isMiniMobile = ref(window.innerWidth <= 435);

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

const initializeAnimation = () => {
	if (!isMiniMobile.value) {
		gsap.registerPlugin(ScrollTrigger);

		gsap.fromTo(
			panel.value,
			{
				clipPath: 'circle(30% at 50% 99%)',
			},
			{
				clipPath: 'circle(120% at 50% 80%)',
				ease: 'none',
				scrollTrigger: {
					trigger: panel.value,
					start: 'top 80%',
					end: 'bottom center', // end 값을 조정하여 패널 전체가 노출되도록 설정
					scrub: true,
				},
			},
		);
	}
};

onMounted(() => {
	window.addEventListener('resize', onResize);
	onResize();

	initializeAnimation();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style>
#main_con01_warp {
	width: 100%;
	min-height: 106vh;
	background-size: cover;
	position: relative;
	background: url('/src/assets/image/main_con01_bg.png') no-repeat center center;
	background-size: cover;
	overflow: visible; /* overflow 속성 확인 */
}

.main_con01_warp_contents {
	position: relative;
	top: 6rem;
}

.main_con01_title {
	font-size: 45px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	z-index: 10; /* 필요에 따라 z-index 조정 */
	position: relative;
	top: 100px;
}

.service_process_text {
	font-size: 18px;
	font-weight: bold;
	padding-bottom: 30px;
}

.main_con01 {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 10;
	background: rgba(255, 255, 255, 0.8);
	padding: 20px;
}

.main_con01 img {
	max-width: 75%;
	height: auto;
	margin-top: 20px;
}

.main_con01_text03 {
	font-size: 18px;
	line-height: 34px;
	color: #686d76;
	padding-top: 30px;
}

.main_con_icons {
	width: 100%;
	margin: 0 auto;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	text-align: center;
	justify-content: center;
}

.main_con_icon_image {
	padding: 0 5px;
}

@media screen and (max-width: 435px) {
	.main_con01_title {
		width: 90%;
		margin: 0 auto;
		text-align: center;
		padding-top: 15%;
	}

	.main_con01_text03 {
		display: flex;
		flex-direction: column;
		text-align: left;
		font-size: 0.7rem;
		line-height: 20px;
		color: #d0d0d0;
		padding-top: 30px;
	}

	.main_con_icons {
		width: 100%;
		margin: 0 auto;
		margin-top: 20px;
		display: flex;
		gap: 10px;
		flex-direction: column;
		text-align: center;
		margin-bottom: 200px;
	}

	.main_con_icon_image {
		width: 160px;
		margin: 0 5px;
	}

	#main_con01_warp {
		min-height: 680px !important;
		height: 680px;
	}

	.service_process_text_mobile {
		font-size: 12px !important;
		text-align: left;
	}

	.service_process_title_mobile {
		font-size: 15px !important;
		text-align: left;
	}
}
</style>
