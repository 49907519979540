<template>
	<div id="main_con03_warp">
		<div class="main_con03_title_wrap">
			<div class="main_con03_title">
				<p
					data-aos="fade-up"
					v-if="isViewTitle"
					data-aos-duration="1000"
					class="main_con03_text01"
					id="main_con03_text01_pc"
				>
					도입사례
				</p>

				<p v-if="isMiniMobile" class="main_con03_text01">도입사례</p>
				<p
					:data-aos="isMiniMobile ? '' : 'fade-up'"
					v-if="isViewTitle"
					data-aos-duration="950"
					class="main_con03_text02"
					data-aos-delay="50"
				>
					드제이DX의 스마트팩토리 도입사례를 확인해보세요.
				</p>
			</div>
			<div class="main_con03_text">
				<img :src="require('@/assets/image/main_con03_text.png')" />
			</div>
		</div>
		<Carousel
			ref="carousel"
			:wrap-around="true"
			:breakpoints="breakpoints"
			autoplay="3000"
			class="main_con3_carousel"
		>
			<Slide
				class="main_con03"
				v-for="item in useCaseContents"
				:key="item.enTItle"
			>
				<div class="main_con03_left_right">
					<div class="main_con03_left">
						<img :src="require('@/assets/image/' + item.image)" />
					</div>
					<div class="main_con03_right">
						<p class="main_con03_text03">{{ item.krTitle }}</p>
						<p class="main_con03_text04">{{ item.enTitle }}</p>
						<p v-html="item.contents" class="main_con03_text05"></p>
						<RouterLink :to="item.path">
							<p class="main_con03_text06">
								More view &nbsp;&nbsp;<img
									:src="require('@/assets/image/main_con03_btn.png')"
								/></p
						></RouterLink>
					</div>
				</div>
				<div class="main_con03_arrow_left" @click="prevSlideChange">
					<img :src="require('@/assets/image/main_con03_arrow_left.png')" />
				</div>
				<div class="main_con03_arrow_right" @click="nextSlideChange">
					<img :src="require('@/assets/image/main_con03_arrow_right.png')" />
				</div>
			</Slide>
		</Carousel>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

const carousel = ref(null);
const isViewTitle = ref(false);
const isMiniMobile = ref(window.innerWidth <= 435);

const prevSlideChange = () => {
	carousel.value.prev();
};

const nextSlideChange = () => {
	carousel.value.next();
};

const useCaseContents = [
	{
		krTitle: '제조 관리 시스템',
		enTitle: 'Manufacturing Execution System',
		contents: `- 입고/출고/Kitting 등 자재 관리<br>
				- 생산공정 모바일화 및 설비 제어<br>
				- 공정 별 Batch 매칭 및 W/O 지정<br>
				- 팔레트 및 박스 관리 및 Tracking<br>
				- 자재 바코드 및 Lot card 조회`,
		image: 'main_con03-1.png',
		path: '/useCaseDetail/nextmes',
	},
	{
		krTitle: '설비 관리시스템',
		enTitle: 'Environmental Management System',
		contents: `- 사용자 기준정보 관리 및 권한 관리<br>
				- 단말기 관리 및 접속이력 추적<br>
				- 사용자 작업시간 Tracking <br>
				- 실시간 작업현황 및 사용자 작업상태 관리<br>
				- W/O 생성 및 설비 점검내용 EMS 공유`,
		image: 'main_con03-2.png',
		path: '/useCaseDetail/ems',
	},
	{
		krTitle: '생산 관리시스템',
		enTitle: 'Manufacturing Execution System',
		contents: `- 공정 / 부서 / 설비 별 점검 현황<br>
				- 점검 결재 현황<br>
				- 활성 / 비활성 시트 관리<br>
				- BATCH HISTORY 관리<br>
				- 사전 비가동 예약기능`,
		image: 'main_con03-3.png',
		path: '/useCaseDetail/mes',
	},
];

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	window.addEventListener('resize', onResize);

	onResize();

	const observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					isViewTitle.value = true;
				} else {
					isViewTitle.value = false;
				}
			});
		},
		{ threshold: 0.1 },
	);

	onUnmounted(() => {
		window.removeEventListener('resize', onResize);
	});

	const ele = document.querySelector('.main_con03_text');
	observer.observe(ele);
});
</script>

<style>
.carousel__viewport {
	overflow: hidden;
}

#main_con03_warp {
	position: relative;
	width: 100%;
	min-height: 1000px;
	background: url('/src/assets/image/main_con03_bg.png') center top;
	background-size: cover;
	overflow: hidden;
}

.main_con03_title {
	position: absolute;
	width: 100%;
	text-align: center;
	left: 0%;
	z-index: 99;
	top: 180px;
}

.main_con03 {
	position: relative;
	clear: both;
	width: 100%;
	margin: 0 auto;
	padding-top: 450px;
	overflow: hidden;
}

.main_con03_left {
	width: 960px;
	z-index: 9999;
}

.main_con03_left_right {
	display: flex;
}

.main_con03_left img {
	position: relative;
	right: 150px;
	bottom: 90px;
	width: 100%;
}

.main_con03_right {
	position: absolute;
	width: 380px;
	height: 400px;
	border: 1px solid #dcdcdc;
	background: #fff;
	z-index: 99999;
	left: 50%;
	margin-left: 240px;
	top: 415px;
}

.main_con03_text {
	position: absolute;
	width: 900px;
	top: 90px;
	z-index: 1;
	left: 50%;
	margin-left: -480px;
}

.main_con03_text01 {
	font-size: 50px;
	font-weight: bold;
	line-height: 62px;
	padding-top: 5px;
}

.main_con03_text02 {
	font-size: 20px;
	/* padding-top: 30px; */
	color: #7d7d7d;
}

.main_con03_text03 {
	font-size: 34px;
	font-weight: bold;
	padding-top: 40px;
}

.main_con03_text04 {
	font-size: 16px;
	padding-top: 10px;
}

.main_con03_text05 {
	font-size: 17px;
	line-height: 32px;
	padding-top: 30px;
	color: #a7a7a7;
}

.main_con03_text06 {
	font-size: 17px;
	padding-top: 30px;
}

.main_con03_arrow_left {
	position: absolute;
	left: 1%;
	top: 600px;
	z-index: 99999;
}

.main_con03_arrow_right {
	position: absolute;
	right: 1%;
	top: 600px;
	z-index: 99999;
}

@media screen and (max-width: 768px) {
	#main_con03_warp {
		position: relative;
		width: 100%;
		height: 200px;
		background-size: cover;
	}

	.main_con03_title {
		position: absolute;
		width: 100%;
		text-align: center;
		left: 0%;
		z-index: 99;
		top: 20px;
	}

	.main_con03 {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0 auto;
		padding-top: 0px;
		overflow: hidden;
	}

	.main_con03_left {
		position: relative;
		left: 150px;
		z-index: 9999;
		width: 100%;
		margin: 0 auto;
	}

	.main_con03_left img {
		width: 500px;
	}

	.main_con03_right {
		position: static;
		width: 500px;
		border: 1px solid #dcdcdc;
		background: #fff;
		z-index: 0;
		margin: 0;
	}

	.main_con03_text {
		position: absolute;
		width: 100%;
		top: 20px;
		z-index: 1;
		left: 0%;
		margin-left: 0px;
		text-align: center;
	}

	.main_con03_text img {
		width: 60%;
	}

	.main_con03_text01 {
		font-size: 18px;
		font-weight: bold;
		line-height: 22px;
		padding-top: 20px;
	}

	.main_con03_text02 {
		font-size: 12px;
		padding-top: 10px;
		color: #7d7d7d;
	}

	.main_con03_text03 {
		font-size: 18px;
		font-weight: bold;
		padding-top: 20px;
	}

	.main_con03_text04 {
		font-size: 12px;
		padding-top: 10px;
	}

	.main_con03_text05 {
		font-size: 12px;
		line-height: 20px;
		padding-top: 10px;
		color: #a7a7a7;
	}

	.main_con03_text06 {
		font-size: 14px;
		padding-top: 10px;
	}

	.main_con03_arrow_left {
		position: absolute;
		width: 34px;
		left: 0%;
		top: 36%;
		z-index: 99999;
	}

	.main_con03_arrow_left img {
		width: 100%;
	}

	.main_con03_arrow_right {
		position: absolute;
		width: 34px;
		right: 0%;
		top: 36%;
		z-index: 99999;
	}

	.main_con03_arrow_right img {
		width: 100%;
	}

	@media screen and (max-width: 435px) {
		.main_con03_text {
			position: absolute;
			width: 400px;
			top: 47px;
			z-index: 1;
		}

		.main_con03_title {
			position: absolute;
			width: 100%;
			text-align: center;
			left: 0%;
			z-index: 99;
			top: 50px;
		}

		#main_con03_text01_pc {
			display: none;
		}

		.main_con03_text01 {
			font-size: 25px;
			/* padding-bottom: 150px; */
		}

		#main_con03_warp {
			min-height: 700px;
			background: url('/src/assets/image/main_con03_bg.png') center top;
			background-size: cover;
			/* overflow: hidden; */
		}

		.main_con03 {
			position: relative;
			bottom: 40px;
			width: 100%;
			margin: 0 auto;
			overflow: hidden;
		}

		.main_con03_arrow_left {
			position: absolute;
			left: 1%;
			z-index: 99999;
			top: 36%;
		}

		.main_con03_arrow_right {
			position: absolute;
			right: 1%;
			z-index: 99999;
			top: 36%;
		}

		.main_con03_left_right {
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			position: relative;
		}

		.main_con03_left {
			height: 100%;
		}
		.main_con03_left img {
			width: 100%;
			position: absolute;
			top: 15px;
			height: 220px;
		}

		.main_con03_right {
			width: 380px;
			height: 470px;
			border: 1px solid #dcdcdc;
			background: #fff;
			z-index: 99999;
			margin: 0 auto;
		}
	}
}
</style>
