import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutUsView from '@/views/AboutUsView.vue';
import NotFound from '@/layout/NotFound.vue';
import UseCaseView from '@/views/UseCaseView.vue';
import SmartFactoryView from '@/views/SmartFactoryView.vue';
import UseCaseEMSDetail from '@/components/menus/useCase/UseCaseEMSDetail.vue';
import UseCaseNextMESDetail from '@/components/menus/useCase/UseCaseNextMESDetail.vue';
import UseCaseMESDetail from '@/components/menus/useCase/UseCaseMESDetail.vue';
import UseCaseMMESDetail from '@/components/menus/useCase/UseCaseM-MESDetail.vue';
import UseCaseDetailHome from '@/components/menus/useCase/UseCaseDetailHome.vue';
import ContactView from '@/views/ContactView.vue';
import UseCaseMCADetail from '@/components/menus/useCase/UseCaseMCADetail.vue';
import AboutUsClientsView from '@/views/AboutUsClientsView.vue';
import SmartFactoryProcessView from '@/views/SmartFactoryProcessView.vue';
import SolutionView from '@/views/SolutionView.vue';
import SolutionDetailHome from '@/components/menus/solution/SolutionDetailHome.vue';
import SolutionAIOCRDetail from '@/components/menus/solution/SolutionAI-OCRDetail.vue';
import SolutionDXSignDetail from '@/components/menus/solution/SolutionDXSignDetail.vue';

const routes = [
	{
		path: '/',
		component: HomeView,
	},
	{
		path: '/aboutUs',
		component: AboutUsView,
	},
	{
		path: '/smartFactory',
		component: SmartFactoryView,
	},
	{
		path: '/clients',
		component: AboutUsClientsView,
	},
	{
		path: '/process',
		name: 'smartFactoryProcess',
		component: SmartFactoryProcessView,
	},
	{
		path: '/useCase',
		component: UseCaseView,
	},
	{
		path: '/useCaseDetail',
		name: 'UseCaseDetailHome',
		component: UseCaseDetailHome,
		children: [
			{
				path: '',
				name: 'UseCaseDetailHomeNotFound',
				component: NotFound,
			},
			{
				path: 'ems',
				name: 'UseCaseEMSDetail',
				component: UseCaseEMSDetail,
			},
			{
				path: 'nextmes',
				name: 'UseCaseNextMESDetail',
				component: UseCaseNextMESDetail,
			},
			{
				path: 'mes',
				name: 'UseCaseMESDetail',
				component: UseCaseMESDetail,
			},

			{
				path: 'mmes',
				name: 'UseCaseM-MESDetail',
				component: UseCaseMMESDetail,
			},

			{
				path: 'mca',
				name: 'UseCaseMCADetail',
				component: UseCaseMCADetail,
			},
		],
	},
	{
		path: '/solution',
		component: SolutionView,
	},
	{
		path: '/solutionDetail',
		name: 'SolutionDetailHome',
		component: SolutionDetailHome,
		children: [
			{
				path: '',
				name: 'SolutionDetailHomeNotFound',
				component: NotFound,
			},
			{
				path: 'ai-ocr',
				name: 'SolutionAI-OCRDetail',
				component: SolutionAIOCRDetail,
			},
			{
				path: 'dxsign',
				name: 'SolutionDXSignDetail',
				component: SolutionDXSignDetail,
			},
		],
	},
	{
		path: '/contact',
		component: ContactView,
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	if (to.query.redirect) {
		const path = to.query.redirect;
		delete to.query.redirect;
		next({ path, replace: true });
	} else {
		next();
	}
});

export default router;
