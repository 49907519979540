<template>
	<div>
		<QuickButton></QuickButton>
		<AppMobileHeader @open="handleOpenMenu"></AppMobileHeader>
		<div class="header" :class="{ roll: isScrolled }">
			<div class="main_top">
				<div class="logo">
					<RouterLink to="/"
						><img :src="require('@/assets/image/logo.png')"
					/></RouterLink>
				</div>
				<div class="menu">
					<ul>
						<li>
							<RouterLink to="/aboutUs">ABOUT US</RouterLink>
						</li>
						<li>
							<RouterLink to="/smartFactory">SMART FACTORY</RouterLink>
						</li>
						<li>
							<RouterLink to="/solution">SOLUTION</RouterLink>
						</li>
						<li>
							<RouterLink to="/useCase">USE CASE</RouterLink>
						</li>
						<li>
							<RouterLink to="/contact">CONTACT</RouterLink>
						</li>
					</ul>
					<div class="menu_hovered_bg"></div>
					<ul class="menu_hovered">
						<li class="menu_hovered_list">
							<RouterLink to="/aboutUs">회사소개</RouterLink
							><RouterLink to="/clients">고객사</RouterLink>
						</li>

						<li class="menu_hovered_list">
							<RouterLink to="/smartFactory">스마트팩토리란?</RouterLink>
							<RouterLink to="/process">구축 프로세스</RouterLink>
						</li>
					</ul>
				</div>
				<div class="allmenu_btn" @click="handleOpenMenu">
					<img :src="require('@/assets/image/menu_btn.png')" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import QuickButton from '@/components/atoms/QuickButton.vue';
import AppMobileHeader from './AppMobileHeader.vue';
import { ref, onMounted, onUnmounted, defineEmits, watch } from 'vue';
import { useRoute } from 'vue-router';

const emit = defineEmits(['open']);

const isScrolled = ref(false);
const route = useRoute();
const isMounted = ref(false);

onMounted(() => {
	if (route.path === '/') {
		window.addEventListener('scroll', handleScroll);
	}

	isMounted.value = false;
});

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll);
});

function handleScroll() {
	const scrollY = window.scrollY;
	isScrolled.value = scrollY > 0;
}

watch(
	() => route.path,
	newPath => {
		if (newPath === '/') {
			window.addEventListener('scroll', handleScroll);
			handleScroll(); // 초기화 시 스크롤 위치에 따른 isScrolled 상태 업데이트
		} else {
			window.removeEventListener('scroll', handleScroll);
			isScrolled.value = true; // 기본값 true로 설정
		}
	},
);

function handleOpenMenu() {
	emit('open');
}
</script>

<style>
.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	height: 85px;
	line-height: 48px;
	-webkit-transition: height 0.3s;
	-moz-transition: height 0.3s;
	transition: height 0.3s;
}
.header a {
	color: #000;
}

.header.roll {
	height: 85px;
	line-height: 48px;
	background: #fff;
	border-bottom: 1px solid #eaeaea;
}

.header.roll a {
	color: #000;
}
.header .navi {
	width: 100%;
	margin: 0 auto;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.header.roll .navi {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.header h1 {
	float: left;
}

.header .roll .navi ul li a {
	height: 85px;
}

.header .logo a img {
	filter: grayscale(100%) invert(100%) brightness(200%);
	width: 160px;
}
.header.roll .logo a img {
	filter: none;
	width: 160px;
}

.header .allmenu_btn img {
	cursor: pointer;
	filter: grayscale(100%) invert(100%) brightness(200%);
}
.header.roll .allmenu_btn img {
	filter: none;
	cursor: pointer;
}

.main_top {
	/* padding-top: 10px; */
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.logo {
	padding-top: 15px;
	width: 214px;
}

.menu {
	position: relative;
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	padding-left: 100px;
}

.menu_hovered_bg {
	position: fixed;
	top: 85px;
	left: 0;
	width: 100vw;
	height: 120px;
	background: none;
	border-top: 1px solid #eaeaea;
	z-index: 9999;
	display: none; /* 초기 상태에서는 숨김 */
}

.menu_hovered {
	width: 100%;
	height: 50px;
	line-height: 48px;
	display: none; /* 초기 상태에서는 숨김 */
	z-index: 10000; /* 배경보다 위에 표시 */
}

.menu_hovered li {
	margin-top: 30px;
	color: #000 !important;
	display: flex;
	flex-direction: column;
}

.menu_hovered li a {
	font-size: 14px;
	margin-top: 0;
	color: #31363f !important;
}

.menu_hovered li a:hover {
	color: #24d2dc !important;
}

.header:hover .menu_hovered_bg,
.header:hover .menu_hovered {
	display: block; /* 메뉴에 마우스가 올라가면 표시 */
}

.header:hover {
	height: 85px;
	line-height: 48px;
	background: #fff;
	animation: opacityFadeIn 0.3s ease-in-out forwards;
}

.header:hover .logo a img {
	filter: none;
	width: 160px;
}

.header:hover .menu_hovered_bg {
	background: #f1f1f1 !important;
	animation: slideDownFadeIn 0.3s ease-in-out forwards;
	overflow: hidden;
}

.menu_hovered_list {
	height: 85px;
	animation: opacityFadeIn 0.3s ease-in-out forwards;
}

@keyframes slideDownFadeIn {
	0% {
		opacity: 0;
		height: 0;
	}
	100% {
		opacity: 1;
		height: 120px; /* Adjust this value as needed */
	}
}

@keyframes opacityFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.header:hover a {
	color: #000;
}

.header:hover .allmenu_btn img {
	filter: none;
}

.header.roll .menu_hovered_bg {
	background: #f1f1f1 !important;
	border-bottom: 1px solid #eaeaea;
}

.menu ul li {
	font-family: 'GmarketSansMedium', dotum, sans-serif;
	font-size: 17px;
	color: #3d4c2a;
	float: left;
	min-width: 200px;
	font-weight: bold;
}

.menu ul li a {
	margin-right: 90px;
	color: #fff;
}
.menu ul li a:hover {
	color: #24d2dc;
}

.allmenu_btn {
	padding-top: 15px;
}

@media (max-width: 1400px) and (min-width: 1024px) {
	.logo a img {
		width: 150px;
	}
	.menu ul li {
		font-size: 0.9rem;
	}

	.menu ul li a {
		margin-right: 35px;
		color: #fff;
	}
}

@media (max-width: 1024px) and (min-width: 768px) {
	.logo a img {
		width: 120px;
	}
	.menu ul li {
		font-size: 0.7em;
	}

	.menu ul li a {
		margin-right: 30px;
		color: #fff;
	}
}

@media screen and (max-width: 768px) {
	.header {
		display: none;
	}
}
</style>
