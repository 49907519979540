<template>
	<div id="all_menu_warp">
		<div class="all_menu">
			<div class="all_menu_left_container">
				<div class="all_menu_top">
					<div class="all_menu_logo">
						<RouterLink to="/" @click="clickedCloseMenu"
							><img :src="require('@/assets/image/logo.png')"
						/></RouterLink>
					</div>
				</div>
				<div class="all_menu_bottom">
					<div class="all_menu_data">
						<div class="all_menu_text01">
							<a
								@mouseover="changeImage(true)"
								@mouseleave="changeImage(false)"
								@click.prevent="downloadFile"
								class="pdf_download_icon"
								>회사소개서 &nbsp;
								<img :src="require('@/assets/image/all_menu_download.png')"
							/></a>
						</div>

						<div class="all_menu_data01">
							<p class="all_menu_text02">Address</p>
							<p class="all_menu_text03">
								서울특별시 마포구 월드컵북로 5길 12 2층
							</p>

							<p class="all_menu_text02">Tel</p>
							<p class="all_menu_text03">02-2266-4433</p>

							<p class="all_menu_text02">E-mail</p>
							<p class="all_menu_text03">hcan@dejay.co.kr</p>
						</div>
					</div>
				</div>
			</div>
			<div class="all_menu_list">
				<ul>
					<li>
						<RouterLink to="/aboutUs" @click="clickedCloseMenu"
							>ABOUT US</RouterLink
						>
					</li>
					<li class="menu_second_child">
						<RouterLink to="/aboutUs" @click="clickedCloseMenu"
							>회사소개</RouterLink
						>
					</li>
					<li class="menu_second_child">
						<RouterLink to="/clients" @click="clickedCloseMenu"
							>고객사</RouterLink
						>
					</li>
					<li>
						<RouterLink to="/smartFactory" @click="clickedCloseMenu"
							>SMART FACTORY</RouterLink
						>
					</li>
					<li class="menu_second_child">
						<RouterLink to="/smartFactory" @click="clickedCloseMenu"
							>스마트팩토리란?</RouterLink
						>
					</li>
					<li class="menu_second_child">
						<RouterLink to="/process" @click="clickedCloseMenu"
							>구축 프로세스</RouterLink
						>
					</li>
					<li>
						<RouterLink to="/solution" @click="clickedCloseMenu"
							>SOLUTION</RouterLink
						>
					</li>
					<li>
						<RouterLink to="/useCase" @click="clickedCloseMenu"
							>USE CASE</RouterLink
						>
					</li>
					<li>
						<RouterLink to="/contact" @click="clickedCloseMenu"
							>CONTACT</RouterLink
						>
					</li>
				</ul>
			</div>
		</div>
		<div class="all_menu_close">
			<img
				:src="require('@/assets/image/all_menu_close.png')"
				@click="clickedCloseMenu"
			/>
		</div>
	</div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const emit = defineEmits(['close']);

const defaultImage = require('@/assets/image/all_menu_download.png');
const hoverImage = require('@/assets/image/all_menu_download_hover.png');

const imgSrc = ref(defaultImage);

function changeImage(isHover) {
	imgSrc.value = isHover ? hoverImage : defaultImage;
}

function clickedCloseMenu() {
	emit('close');
}

function downloadFile() {
	const file = '/resources/Dejay_Intro_2022.pdf';

	const link = document.createElement('a');
	link.href = file;
	link.download = '회사소개서.pdf';

	link.click();
}
</script>

<style>
#all_menu_warp {
	position: fixed;
	width: 100%;
	height: 100vh;
	background: #000;
	z-index: 9999999999;
	overflow: hidden;
}

.all_menu {
	max-width: 1400px;
	margin: 0 auto;
	padding-top: 100px;
	display: flex;
	justify-content: space-between;
}

.all_menu_top {
	width: 100%;
}

.all_menu_close {
	position: absolute;
	left: 89%;
	bottom: 89%;
}

.all_menu_close img {
	cursor: pointer;
}

.all_menu_bottom {
	width: 100%;
	display: flex;
}

.all_menu_left_container {
	width: 40%;
}

.all_menu_data {
	width: 100%;
}

.all_menu_list {
	width: 50%;
	display: flex;
	padding-top: 45px;
}

.all_menu_list ul {
	display: flex;
	flex-direction: column;
}

.all_menu_list ul li {
	font-family: 'GmarketSansMedium', dotum, sans-serif;
	font-size: 70px;
	font-weight: bold;
	color: #fff;
	padding-top: 40px;
	white-space: nowrap;
}

.all_menu_list ul li:nth-child(1) {
	padding-top: 0px;
}

.all_menu_list ul li a {
	color: #fff;
}

.all_menu_list ul li a:hover {
	color: #24d2dc;
}

.all_menu_data01 {
	position: absolute;
	bottom: 80px;
}
.all_menu_text01 {
	padding-top: 35px;
}

.all_menu_text01 a {
	font-size: 22px;
	color: #fff;
}

.all_menu_text02 {
	font-size: 17px;
	color: #656565;
	padding-top: 15px;
}

.all_menu_text03 {
	font-size: 17px;
	color: #fff;
	padding-top: 10px;
}

.all_menu_logo a img {
	filter: grayscale(100%) invert(100%) brightness(200%);
}

.menu_second_child {
	display: none;
	padding-top: 10px !important;
}

.menu_second_child a {
	font-size: 12px !important;
	color: #aeaeae !important;
}

.pdf_download_icon {
	cursor: pointer;
}

.pdf_download_icon:hover {
	color: #24d2dc;
}

.pdf_download_icon:hover img {
	filter: invert(0%) sepia(99%) saturate(2000%) hue-rotate(139deg)
		brightness(100%) contrast(100%);
}

.all_menu_text01 img {
	width: 20px;
	height: 20px;
	margin-left: -10px;
}

@media screen and (max-width: 435px) {
	.all_menu {
		flex-direction: column;
	}
	.all_menu_list {
		width: 40%;
		display: flex;
	}

	.all_menu_list ul {
		display: flex;
		flex-direction: column;
		margin-top: 5px;
		gap: 15px;
		width: 100%;
		flex-wrap: nowrap;
		margin-left: 10px;
	}

	.all_menu_list ul li {
		font-size: 15px;
		font-weight: bold;
		color: #fff;
		padding-top: 10px;
		width: 100%;
		white-space: nowrap;
	}

	.all_menu_text01 {
		font-size: 13px;
		color: #fff;
		padding-top: 20px;
		padding-left: 10px;
	}

	.all_menu_logo {
		margin-left: 6px;
	}

	.all_menu_text01 img {
		width: 15px;
		margin-left: 0px;
	}

	.all_menu_text02 {
		margin-left: 10px;
		font-size: 12px;
		color: #656565;
		padding-top: 20px;
	}

	.all_menu_text03 {
		margin-left: 10px;
		font-size: 12px;
		color: #fff;
		padding-top: 10px;
	}

	.all_menu_data01 {
		display: none;
	}

	.menu_second_child {
		display: block;
	}

	.all_menu_text01 a {
		font-size: 13px;
	}
}
</style>
