<template>
	<div>
		<h2>404 Not Found</h2>
		<div>요청한 페이지를 찾을 수 없습니다.</div>
	</div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
