<template>
	<div class="use_case_detail">
		<div class="sub42_con_title">
			<p class="sub42_text01">SOLUTION</p>
		</div>

		<div class="sub42_list_title">{{ solutionTitle }}</div>
		<router-view @title-update="updateSolutionTitle"></router-view>
		<div class="sub42_con03">
			<div class="sub42_con03_text">
				DEJAY DX에서 올바른 분석을 통한<br />
				<span style="font-weight: bold"
					>최적화된 스마트팩토리를 경험해 보세요.</span
				>
			</div>
			<div class="sub42_btn">
				<RouterLink to="/contact">문의하기</RouterLink>
			</div>
		</div>
		<AboutMoreBanner></AboutMoreBanner>
	</div>
</template>

<script setup>
import AboutMoreBanner from '@/components/menus/about/AboutMoreBanner.vue';
import { ref, onMounted, onUnmounted } from 'vue';

const solutionTitle = ref('');
const isMiniMobile = ref(window.innerWidth <= 435);

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	window.addEventListener('resize', onResize);

	onResize();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});

const updateSolutionTitle = title => {
	solutionTitle.value = title;
};
</script>

<style lang="scss" scoped></style>
