<template>
	<div class="about_clients_wrap">
		<div class="sub12_con_title">
			<p class="sub12_text01">OUR PARTNER</p>
			<p class="sub12_text02">
				DEJAY DX는 스마트 팩토리 전문 기업으로<br />
				고객사와 미래를 함께 만들어 나갑니다.
			</p>
		</div>

		<div id="sub12_con01_warp">
			<div class="sub12_con01">
				<ul>
					<ul class="sub12_con01_col">
						<li><img :src="require('@/assets/image/main_con04-1.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-2.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-3.png')" /></li>
					</ul>
					<ul class="sub12_con01_col">
						<li><img :src="require('@/assets/image/main_con04-4.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-5.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-6.png')" /></li>
					</ul>
					<ul class="sub12_con01_col">
						<li><img :src="require('@/assets/image/main_con04-7.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-8.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-9.png')" /></li>
					</ul>
					<ul class="sub12_con01_col">
						<li><img :src="require('@/assets/image/main_con04-10.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-11.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-12.png')" /></li>
					</ul>
					<ul class="sub12_con01_col">
						<li><img :src="require('@/assets/image/main_con04-13.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-14.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-15.png')" /></li>
					</ul>
					<ul class="sub12_con01_col">
						<li><img :src="require('@/assets/image/main_con04-16.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-17.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-18.png')" /></li>
					</ul>
					<ul class="sub12_con01_col">
						<li><img :src="require('@/assets/image/main_con04-19.png')" /></li>
						<li><img :src="require('@/assets/image/main_con04-20.png')" /></li>
					</ul>
				</ul>
			</div>
		</div>
	</div>
</template>

<script setup></script>

<style>
.sub12_con_title {
	width: 100%;
	text-align: center;
}

.sub12_text01 {
	margin-top: 180px;
	font-size: 60px;
	letter-spacing: -1px;
	font-weight: bold;
}

.sub12_text02 {
	font-size: 22px;
	line-height: 30px;
	padding-top: 20px;
}

#sub12_con01_warp {
	width: 1400px;
	margin: 0 auto;
}

.sub12_con01 {
	width: 1400px;
	height: 660px;
	margin: 0 auto;
	padding-top: 60px;
}

.sub12_con01 ul ul li {
	float: left;
	width: 265px;
	margin: 7px 7px;
}

@media screen and (max-width: 435px) {
	.sub12_con01 ul {
		display: flex;
		flex-direction: column;
	}

	.sub12_con01 ul ul li {
		width: 110px;
	}

	.sub12_con01 ul ul li img {
		width: 120px;
	}

	.sub12_con01_col {
		display: flex;
		flex-direction: row !important;
	}
	.sub12_text01 {
		font-size: 30px;
	}

	.sub12_text02 {
		font-size: 20px;
	}

	.sub12_con01 {
		width: 100%;
		height: 600px;
		margin: 0 auto;
		padding-top: 60px;
	}

	.about_clients_wrap {
		position: relative;
		height: 900px;
		overflow: hidden;
	}
}
</style>
