<template>
	<div class="use_case_detail">
		<div class="sub42_con_title">
			<p class="sub42_text01">도입사례</p>
		</div>

		<div class="sub42_list_title">{{ useCaseTitle }}</div>
		<router-view @title-update="updateUseCaseTitle"></router-view>
		<div class="sub42_con03">
			<div class="sub42_con03_text">
				DEJAY DX에서 올바른 분석을 통한<br />
				<span style="font-weight: bold"
					>최적화된 스마트팩토리를 경험해 보세요.</span
				>
			</div>
			<div class="sub42_btn">
				<RouterLink to="/contact">문의하기</RouterLink>
			</div>
		</div>
		<AboutMoreBanner></AboutMoreBanner>
	</div>
</template>

<script setup>
import AboutMoreBanner from '@/components/menus/about/AboutMoreBanner.vue';
import { ref, onMounted, onUnmounted } from 'vue';

const useCaseTitle = ref('');
const isMiniMobile = ref(window.innerWidth <= 435);

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	window.addEventListener('resize', onResize);

	onResize();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});

const updateUseCaseTitle = title => {
	useCaseTitle.value = title;
};
</script>

<style>
.use_case_detail {
	width: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
}
.sub42_con_title {
	width: 100%;
	text-align: center;
}

.sub42_text01 {
	margin-top: 180px;
	font-size: 60px;
	letter-spacing: -1px;
	font-weight: bold;
}

.sub42_text02 {
	font-size: 20px;
	color: #989898;
	font-weight: 200;
}

.sub42_list_title {
	width: 1400px;
	height: 86px;
	font-size: 22px;
	padding-top: 30px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	margin: 0 auto;
	margin-top: 80px;
}

.sub42_con03 {
	width: 1400px;
	margin: 0 auto;
	margin-top: 60px;
	padding-top: 60px;
	border-top: 1px solid #ccc;
}

.sub42_con03_text {
	text-align: center;
	font-size: 42px;
	line-height: 56px;
}

.sub42_btn {
	width: 200px;
	margin: 0 auto;
	height: 54px;
	background: #004b96;
	color: #fff;
	text-align: center;
	padding-top: 12px;
	font-size: 24px;
	font-weight: bold;
	margin-top: 40px;
}

.sub42_btn a {
	display: block;
	color: #fff;
}
.sub42_con02_list ul li {
	font-size: 20px;
}

@media screen and (max-width: 435px) {
	.sub42_text01 {
		font-size: 35px;
	}

	.sub42_list_title {
		font-size: 20px;
		padding-left: 10px;
		line-height: 32px;
	}

	.sub42_con03 {
		width: 100%;
		margin-top: 60px;
		padding-top: 60px;
		border-top: 1px solid #ccc;
		text-align: left;
	}

	.sub42_con03_text {
		font-size: 20px;
		line-height: 30px;
		text-align: left;
		margin-left: 10px;
	}

	.sub42_btn {
		width: 120px;
		margin: 0 auto;
		height: 35px;
		line-height: 12px;
		background: #004b96;
		color: #fff;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		margin-top: 20px;
	}

	.sub42_con02_list ul li {
		font-size: 15px;
	}
}
</style>
