import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/index.css';
import { createPinia } from 'pinia';
import router from './router';
import { useKakao } from 'vue3-kakao-maps/@utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import smoothscroll from 'smoothscroll-polyfill';
import VueSweetAlert from 'vue-sweetalert2';

// Polyfill 초기화
smoothscroll.polyfill();
// Pinia 생성
const pinia = createPinia();

// Vue 애플리케이션 생성 및 설정
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(Carousel);
app.use(Slide);
app.use(VueSweetAlert);
AOS.init();

// Kakao Maps 초기화
useKakao('3474513e4e854e5df880dd4a40ba3a85');

// 애플리케이션 마운트
app.mount('#app');
