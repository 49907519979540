<template>
	<div>
		<div class="sub51_con_title">
			<p class="sub51_text01">CONTACT</p>
			<p class="sub51_text02">
				문의를 남겨 주시면, 드제이DX 전문가가 <br />
				빠르고 정확한 답변을 드리겠습니다.
			</p>
		</div>

		<!-- 고객문의 페이지 첫번째 상단 영역 끝 -->

		<!-- 고객문의 페이지 두번째 상단 영역 시작 -->

		<div id="sub51_con01_warp">
			<!-- <DownloadButton></DownloadButton> -->
			<div class="sub51_con01">
				<div class="sub51_con01_left">
					<div class="sub51_data01">
						<p class="sub51_text03">TEL.</p>
						<p class="sub51_text04">02-2266-4433</p>
					</div>
					<div class="sub51_data01">
						<p class="sub51_text03">E-MAIL.</p>
						<p class="sub51_text04">hcan@dejay.co.kr</p>
					</div>
					<div class="sub51_data01">
						<p class="sub51_text03">ADRESS.</p>
						<p class="sub51_text04">서울특별시 마포구 월드컵북로 5길 12 2층</p>
					</div>
					<div class="sub51_data02"><KakaoMap></KakaoMap></div>
				</div>
				<form @submit.prevent="dialogOpenHandle">
					<div class="sub51_con01_right">
						<div class="sub51_con01_right01">
							<div class="sub51_con01_right01_left">
								<label for="sendername" class="sub51_text05">이름</label>
								<div class="sub51_text06">
									<input
										type="text"
										name="sendername"
										class="sub51_input01"
										v-model="form.name"
										required
									/>
								</div>
							</div>
							<div class="sub51_con01_right01_right">
								<label for="organization" class="sub51_text05">소속</label>
								<div class="sub51_text06">
									<input
										type="text"
										name="organization"
										class="sub51_input01"
										v-model="form.organization"
										required
									/>
								</div>
							</div>
						</div>
						<div class="sub51_con01_right01-2">
							<div class="sub51_con01_right01_left">
								<label for="tel" class="sub51_text05">연락처</label>
								<div class="sub51_text06">
									<input
										type="text"
										name="tel"
										class="sub51_input01"
										v-model="form.tel"
										required
									/>
								</div>
							</div>
							<div class="sub51_con01_right01_right">
								<label for="email" class="sub51_text05">이메일</label>
								<div class="sub51_text06">
									<input
										type="email"
										name="email"
										class="sub51_input01"
										v-model="form.email"
										required
									/>
								</div>
							</div>
						</div>
						<fieldset class="sub51_con01_right02">
							<legend class="sub51_con01_right02_left">문의분야</legend>
							<div class="sub51_con01_right02_right">
								<ul>
									<li>
										<input
											id="inquirytype-homepage"
											name="inquirytype"
											type="radio"
											value="homepage"
											v-model="form.inquiryType"
										/>
										<label class="radio_label" for="inquirytype-homepage"
											>홈페이지</label
										>
									</li>
									<li>
										<input
											id="inquirytype-application"
											name="inquirytype"
											type="radio"
											value="application"
											v-model="form.inquiryType"
										/>
										<label class="radio_label" for="inquirytype-application"
											>어플리케이션</label
										>
									</li>
									<li>
										<input
											id="inquirytype-maintenance"
											name="inquirytype"
											type="radio"
											value="maintenance"
											v-model="form.inquiryType"
										/>
										<label class="radio_label" for="inquirytype-maintenance"
											>유지보수</label
										>
									</li>
									<li>
										<input
											id="inquirytype-etc"
											name="inquirytype"
											type="radio"
											value="etc"
											v-model="form.inquiryType"
										/>
										<label class="radio_label" for="inquirytype-etc"
											>기타</label
										>
									</li>
								</ul>
							</div>
						</fieldset>
						<div class="sub51_con01_right03">
							<div class="sub51_con01_right03_left">문의사항</div>
							<div class="sub51_con01_right03_right">
								<textarea
									required
									id="inquiry_textarea"
									v-model="form.message"
								></textarea>

								<div class="scr">
									<span
										style="
											font-size: 1.2em;
											letter-spacing: -1px;
											line-height: 1.4em;
										"
									>
										○ 수집하는 개인정보의 항목 : 성명, 연락처(유선전화 또는
										휴대폰), 이메일, 소속<br />
										○ 개인정보 수집/이용 목적 : 상담/문의 확인 및 답변,
										제휴문의<br /><br />

										※ 상담/문의 서비스 이용 과정에서 아래와 같은 정보들이
										생성되어 수집 될 수 있습니다. <br />
										&nbsp;&nbsp;&nbsp;&nbsp; - 상담/문의 이용 기록/로그, 쿠키,
										접속 IP정보<br /><br />

										○ 개인정보의 보유 및 이용기간<br />
										&nbsp;&nbsp;&nbsp;&nbsp;-보존 기간은 1년이며, 정보 제공자가
										삭제를 요청할 경우 즉시 파기합니다.<br />
										&nbsp;&nbsp;&nbsp;&nbsp;-사용자의 정보는 개인정보 보호법에
										따라 보호되며 위의 이용 목적 외에 별도로 사용하지 않을 것을
										약속 드립니다.
									</span>
									<br /><br />
								</div>
							</div>
						</div>
						<div class="agreement-checkbox">
							<input
								type="checkbox"
								id="agree"
								name="agree"
								value="true"
								v-model="form.agree"
								required
							/>
							<label for="agree" class="agreement-label"
								>개인정보 수집에 동의합니다.</label
							>
						</div>
						<button type="button" class="send" @click="dialogOpenHandle">
							문의하기
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script setup>
// import DownloadButton from '@/components/atoms/DownloadButton.vue';
import KakaoMap from '@/components/atoms/KakaoMap.vue';
import emailjs from 'emailjs-com';
import { ref } from 'vue';
import Swal from 'sweetalert2';

const form = ref({
	name: '',
	organization: '',
	tel: '',
	email: '',
	inquiryType: '',
	message: '',
	agree: false,
});

function dialogOpenHandle() {
	const inValid =
		form.value.name &&
		form.value.organization &&
		form.value.tel &&
		form.value.email &&
		form.value.inquiryType &&
		form.value.message;

	if (!inValid) {
		Swal.fire('실패', '모두 입력해주세요!', 'error');
		return;
	}

	if (!form.value.agree) {
		Swal.fire('실패', '개인정보 수집에 동의해주세요!', 'error');
		return;
	}
	if (form.value.agree && inValid) {
		Swal.fire({
			title: '발송 확인',
			text: '문의 메일을 발송하시겠습니까?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: '발송',
			cancelButtonText: '취소',
			customClass: {
				confirmButton: 'swal2-confirm',
			},
		}).then(result => {
			if (result.isConfirmed) {
				sendEmail();
			}
		});
	}
}

const sendEmail = () => {
	emailjs
		.send(
			'service_2i7b16r', // EmailJS에서 제공한 서비스 ID
			'template_1y90plk', // EmailJS에서 제공한 템플릿 ID
			form.value,
			'qUViHq8YVCfY_Ze39', // EmailJS에서 제공한 사용자 ID
		)
		.then(() => {
			Swal.fire('성공', '문의 메일이 발송되었습니다.', 'success').then(
				result => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				},
			);
		})
		.catch(error => {
			Swal.fire('실패', '메일 전송 중 오류가 발생했습니다.', 'error');
			console.log(error);
		});
};
</script>

<style>
.sub51_con_title {
	width: 100%;
	text-align: center;
}

.sub51_text01 {
	margin-top: 180px;
	font-size: 60px;
	letter-spacing: -1px;
	font-weight: bold;
}

.sub51_text02 {
	font-size: 22px;
	line-height: 30px;
	padding-top: 20px;
}

.sub51_text03 {
	font-size: 24px;
	font-weight: bold;
	padding-top: 20px;
}

.sub51_text04 {
	font-size: 20px;
	padding-top: 10px;
}

.sub51_text05 {
	width: 74px;
	text-align: right;
	font-size: 20px;
	font-weight: bold;
	padding-top: 4px;
	float: left;
	margin-right: 10px;
}

.sub51_text06 {
	padding-left: 10px;
}

#sub51_con01_warp {
	width: 1400px;
	margin: 0 auto;
	margin-top: 40px;
}

.sub51_con01 {
	width: 100%;
	padding-top: 20px;
	display: flex;
}

.sub51_con01_left {
	width: 700px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.sub51_con01_right {
	width: 700px;
	display: flex;
	flex-direction: column;
}

.sub51_data01 {
	width: 100%;
	padding-top: 20px;
}

.sub51_data02 {
	width: 100vw;
	padding-top: 50px;
}

.sub51_con01_right01 {
	clear: both;
	width: 100%;
	padding-top: 30px;
	padding-left: 20px;
}

.sub51_con01_right01-2 {
	clear: both;
	width: 100%;
	padding-top: 50px;
	padding-left: 20px;
}

.sub51_con01_right01_left {
	width: 45%;
	float: left;
}

.sub51_con01_right01_right {
	width: 45%;
	float: left;
}

.sub51_con01_right02 {
	display: flex;
	width: 100%;
	padding-top: 50px;
	padding-left: 20px;
}

.sub51_con01_right02_left {
	font-size: 20px;
	font-weight: bold;
	position: absolute;
	display: inline-block;
}

.sub51_con01_right02_right {
	width: 600px;
	padding-left: 80px;
	position: relative;
	bottom: 3px;
}

.sub51_con01_right02_right ul li {
	float: left;
	font-size: 18px;
	padding: 4px 20px 0 0;
	margin-left: 10px;
}

.sub51_con01_right03 {
	clear: both;
	width: 100%;
	padding-top: 50px;
	padding-left: 20px;
}

.sub51_con01_right03_left {
	width: 70px;
	float: left;
	text-align: right;
	font-size: 20px;
	font-weight: bold;
	margin-right: 8px;
}

.sub51_con01_right03_right {
	width: 600px;
	float: left;
}

.sub51_input01 {
	position: relative;
	width: 220px;
	height: 30px;
	border: 0px;
	font-size: 17px !important;
	padding: 14px 24px;
	border-bottom: 1px solid #bbb;
	background: none;
	margin-top: 0px;
	color: #000;
}

#inquiry_textarea {
	width: 535px;
	height: 230px;
	font-size: 18px;
	background-color: #fff;
	resize: none;
	color: black;
	border: none;
	outline: none;
	margin-left: 10px;
	border: 1px solid #ccc;
}

.scr {
	width: 535px;
	overflow-y: scroll;
	color: #777;
	padding: 10px;
	border: 1px solid #ccc;
	height: 220px;
	text-align: left;
	margin-left: 10px;
	margin-top: 10px;
}

.send {
	clear: both;
	width: 150px;
	font-size: 20px;
	height: 42px;
	background: #000;
	color: #fff;
	text-align: center;
	padding-top: 3px;
	margin-left: 320px;
	margin-top: 50px;
}

.swal2-confirm {
	background-color: #003285 !important;
	border-color: #003285 !important; /* 원하는 색상으로 변경 */
}

.agreement-checkbox {
	padding-left: 120px;
	padding-top: 20px;
	font-size: 15px;
}

.agreement-label {
	position: relative;
	bottom: 1px;
	left: 2px;
}

.radio_label {
	margin-left: 4px;
}
@media screen and (max-width: 435px) {
	#sub51_con01_warp {
		width: 100%;
	}
	.sub51_con01 {
		flex-direction: column;
	}

	.sub51_con01_left {
		width: 100%;
		height: 700px;
	}

	.sub51_data01,
	.sub51_text03,
	.sub51_text04 {
		width: 330px;
		margin-left: 20px;
	}

	.sub51_text02 {
		font-size: 15px;
	}

	.sub51_text03 {
		font-size: 20px;
	}

	.sub51_text04 {
		font-size: 15px;
	}

	.sub51_text01 {
		font-size: 30px;
		letter-spacing: -1px;
		font-weight: bold;
	}

	.sub51_con01_right {
		width: 330px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
	}
	.sub51_con01_right01 {
		margin: 0;
		padding: 0;
	}

	.sub51_con01_right01_left,
	.sub51_con01_right01_right {
		width: 100% !important;
		float: none !important;
		display: flex;
		flex-direction: column;
		width: 100%;
		float: none;
		margin: 0 auto;
		padding-top: 10px;
		padding-left: 20px;
	}

	.sub51_text05 {
		width: 55px;
		text-align: left;
		font-size: 18px;
	}

	.sub51_input01 {
		position: relative;
		right: 7px;
		width: 290px;
		height: 3px;
		border: 0px;
		font-size: 18px !important;
		padding: 20px 24px;
		border-bottom: 1px solid #bbb;
		background: none;
		margin-top: 0px;
		color: #000;
	}

	.sub51_con01_right01-2 {
		clear: none;
		width: 100%;
		padding: 0;
	}

	.sub51_con01_right02_right ul {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}
	.sub51_con01_right02_left {
		font-size: 18px;
		text-align: left !important;
		margin-left: 10px;
	}

	.sub51_con01_right02_right ul li label {
		font-size: 15px;
		margin-left: 5px;
	}

	.sub51_con01_right02_right ul li {
		margin-top: 5px;
	}

	.sub51_con01_right02_right ul li input {
		width: 15px;
		height: 15px;
	}

	.sub51_con01_right03 {
		margin: 0;
	}

	.sub51_con01_right03_left {
		margin-bottom: 20px;
		font-size: 18px;
		float: none;
		text-align: left;
	}

	.sub51_con01_right02 {
		clear: both;
		width: 100%;
		padding-top: 20px;
		padding-left: 10px;
	}

	#inquiry_textarea {
		width: 300px !important;
		position: relative;
		right: 10px;
		height: 230px;
		font-size: 15px;
		background-color: #fff;
		resize: none;
		color: black;
		border: none;
		outline: none;
		margin-left: 10px;
		border: 1px solid #ccc;
	}

	.scr {
		width: 300px;
		overflow-y: scroll;
		color: #777;
		padding: 10px;
		border: 1px solid #ccc;
		height: 220px;
		text-align: left;
		margin-left: 0;
		margin-top: 10px;
	}

	.send {
		position: relative;
		right: 240px;
		width: 150px;
		font-size: 20px;
		height: 42px;
		background: #000;
		color: #fff;
		text-align: center;
		padding-top: 1px;
		margin-bottom: 30px;
		margin-left: 320px;
		margin-top: 50px;
	}

	.sub51_data02 {
		width: 100vw !important;
		padding-top: 50px;
	}
}
</style>
