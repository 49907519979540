<template>
	<div>
		<!-- 도입사례 뷰페이지 두번째 상단 영역 시작 -->

		<div class="sub42_con01">
			<div class="sub42_stitle_btn">M-MES</div>
			<div class="sub42_stitle">모바일 제조관리 시스템 구축</div>
			<div class="sub42_img01">
				<img :src="require('@/assets/image/m-mesDetailImage1.png')" />
				<img :src="require('@/assets/image/m-mesDetailImage2.png')" />
			</div>
		</div>
		<!-- 도입사례 뷰페이지 두번째 상단 영역 끝 -->

		<!-- 도입사례 뷰페이지 세번째 상단 영역 시작 -->
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 구축
				정보
			</div>
			<div class="sub42_con02_list">
				<ul>
					<li>· 공정 / 부서 / 설비 별 점검 현황</li>
					<li>. SQMS 생성 및 관리</li>
					<li>. ECQ / ECQR 생성 및 관리</li>
					<li>. CheckSheet 관리 및 점검사항 입력</li>
					<li>. 생산 제조관리 공정 별 수치 관리</li>
					<li>. 공정, 설비, 라인 별 현황 관리</li>
				</ul>
			</div>
		</div>
		<!-- 도입사례 뷰페이지 세번째 상단 영역 끝 -->

		<!-- 도입사례 뷰페이지 네번째 상단 영역 시작 -->
		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 진행
				과정
				<div class="sub42_text02">
					컨설팅 > 기획 > 디자인 > 개발
					<br v-if="isMiniMobile" />
					> 현지 셋업 ( 말레이시아, 천진, 서안, 헝가리, 울산)
				</div>
			</div>
			<div class="sub42_con02_img">
				<img
					v-if="!isMiniMobile"
					:src="require('@/assets/image/sub42-2.png')"
				/>
				<div class="sub42_con02_img_col1" v-if="isMiniMobile">
					<img
						:src="require('@/assets/image/usecase_detail_process_icon01.png')"
					/>
					<img
						:src="require('@/assets/image/usecase_detail_process_icon02.png')"
					/>
					<img
						:src="require('@/assets/image/usecase_detail_process_icon03.png')"
					/>
				</div>
				<div class="sub42_con02_img_col2" v-if="isMiniMobile">
					<img
						:src="require('@/assets/image/usecase_detail_process_icon04.png')"
					/>
					<img
						:src="require('@/assets/image/usecase_detail_process_icon05.png')"
					/>
				</div>
			</div>
		</div>
		<!-- 도입사례 뷰페이지 네번째 상단 영역 끝 -->

		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 설비
				관리 시스템 Admin WEB 구축 / M-EMS
			</div>
			<div class="sub42_con02_list">
				<ul>
					<li>
						· 공정 / 부서 / 설비 별 PM, BM 점검 및 발생 현황을 WEB에서<br
							v-if="isMiniMobile"
						/>
						그래프로 실시간 파악하여 빠른 대응작업 가능
					</li>
					<li>· 현재 작업자들의 접속상태, 실시간 작업상태 Tracking 가능</li>
					<li>
						· 기존 현장에서 체크한 후 데스크탑 PC에 돌아와서 입력하여야
						<br v-if="isMiniMobile" />했던 사용자 동선 개선
					</li>
					<li>
						· 공장 별 제조 공정 및 환경에 따른 Customizing
						<br v-if="isMiniMobile" />설계 및 개발 구현
					</li>
				</ul>
			</div>
		</div>

		<div class="sub42_con02">
			<div class="sub42_con02_stitle">
				<img :src="require('@/assets/image/sub21_con01_stitle.png')" /> 설비
				관리 시스템 APP 구축 / M-EMS
			</div>
			<div class="sub42_con02_list">
				<ul>
					<li>
						· BM 발생, MES 설비 인터락, Warning 이력 알림 기능으로
						<br v-if="isMiniMobile" />설비이상 즉각 감지 가능
					</li>
					<li>
						· 기존 설비점검을 위해 존재하던 점검항목 및 매뉴얼 데이터를
						<br v-if="isMiniMobile" />APP에서 활용 가능하여, 페이퍼워크 감소 및
						설비 관리 <br v-if="isMiniMobile" />효율 증대
					</li>
					<li>
						· NFC 태깅을 통한 사용자 작업시간 및 품질관리 시간,
						<br v-if="isMiniMobile" />검증시간 관리 가능
					</li>
					<li>
						· 공장 별 제조 공정 및 환경에 따른 Customizing
						<br v-if="isMiniMobile" />설계 및 개발 구현
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onUnmounted, onMounted, defineEmits } from 'vue';

const emit = defineEmits(['title-update']);
const isMiniMobile = ref(window.innerWidth <= 435);

function updateHomeTitle() {
	emit('title-update', '제조 관리 시스템 구축 사례');
}

const onResize = () => {
	isMiniMobile.value = window.innerWidth <= 435;
};

onMounted(() => {
	window.addEventListener('resize', onResize);

	onResize();
	updateHomeTitle();
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});
</script>

<style>
.sub42_con01 {
	width: 1400px;
	margin: 0 auto;
	padding-top: 40px;
}

.sub42_stitle_btn {
	width: 100px;
	height: 36px;
	background: #004b96;
	color: #fff;
	text-align: center;
	font-size: 22px;
	font-weight: bold;
	padding-top: 4px;
}

.sub42_stitle {
	font-size: 40px;
	font-weight: bold;
	padding-top: 20px;
}

.sub42_img01 {
	width: 1400px;
	margin: 0 auto;
	padding-top: 40px;
}

.sub42_con02 {
	width: 1400px;
	margin: 0 auto;
	padding-top: 60px;
}

.sub42_con02_stitle {
	width: 1400px;
	margin: 0 auto;
	font-size: 30px;
	font-weight: bold;
}

.sub42_con02_list {
	font-size: 20px;
	line-height: 32px;
	padding-top: 20px;
}

.sub42_con02_img {
	padding-top: 40px;
}
</style>
